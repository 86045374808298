import { Product } from "../../interface/product/Product";
import standardChannelLetter from "../../assets/images/ChannelLetter/Standard/standardChannelLetter.jpg";
import standardChannelLetter2 from "../../assets/images/ChannelLetter/Standard/StandardChannelLetter2.png";
import standardCLExample from "../../assets/images/ChannelLetter/Standard/standardCLExample.jpg";

export const products: Product[] = [
  {
    name: "Signs/Letters",
    url: "/signs-letters",
    subCategories: [
      {
        name: "Channel Letter",
        description:
          "Ready to install, LED illuminated, UL Listed, with power supplies and installation patterns",
        path: "/channel-letter",
        products: [
          {
            name: "Standard Channel Letters",
            path: "/standard-channel-letters",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
          },
          {
            name: "Halo/Reverse Lit Channel Letters",
            path: "/halo-reverse-channel-letters",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            options: [
              {
                name: "Reverse Lit",
              },
            ],
          },
          {
            name: "Premium Channel Letters",
            path: "/standard-channel-letters",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            options: [
              {
                name: "Full Acrylic Face Lit",
              },
              {
                name: "Inset Acrylic Face Lit",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Indoor/Outdoor Displays",
    url: "/displays",
    subCategories: [
      {
        name: "Advertising Flags",
        path: "/advertising-flags",
        description:
          "We have different shapes and sizes for your indoor/outdoor flag purposes",
        products: [
          {
            name: "Feather Angled Flag",
            path: "/feather-angled",
            description: "Indoor and Outdoor bases. Single and Double Sided",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            flagOptions: [
              {
                name: "Flag + Pole",
                price: 28.6,
              },
              {
                name: "Flag Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: "Small 9ft",
                price: 32.99,
              },
              {
                name: "Medium 10.5ft",
                price: 32.99,
              },
              {
                name: "Large 14ft",
                price: 34.99,
              },
              {
                name: "X-Large 18ft",
                price: 46.99,
              },
            ],
            graphic: [
              {
                name: "Single Sided Print Thru",
                price: 0,
              },
              {
                name: "Single Sided Print Thru Reverse",
                price: 0,
              },
              {
                name: "Double Sided",
                price: 40.0,
              },
            ],
            base: [
              {
                name: "Ground Stake",
                price: 0,
              },
              {
                name: "Cross Base",
                price: 11,
              },
              {
                name: "Cross Base + Ground Stake",
                price: 22.75,
              },
              {
                name: "Cross Base + Water Bag",
                price: 14.3,
              },
              {
                name: "Square Base",
                price: 13.65,
              },
            ],
            carryBag: [
              {
                name: "Yes",
                price: 15,
              },
              {
                name: "No",
                price: 0,
              },
            ],
          },
          {
            name: "Feather Convex Flag",
            path: "/feather-convex",
            description: "Indoor and Outdoor bases. Single and Double Sided",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            flagOptions: [
              {
                name: "Flag + Pole",
                price: 28.6,
              },
              {
                name: "Flag Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: "Small 9ft",
                price: 32.99,
              },
              {
                name: "Medium 10.5ft",
                price: 32.99,
              },
              {
                name: "Large 14ft",
                price: 34.99,
              },
              {
                name: "X-Large 18ft",
                price: 46.99,
              },
            ],
            graphic: [
              {
                name: "Single Sided Print Thru",
                price: 0,
              },
              {
                name: "Single Sided Print Thru Reverse",
                price: 0,
              },
              {
                name: "Double Sided",
                price: 40,
              },
            ],
            base: [
              {
                name: "Ground Stake",
                price: 0,
              },
              {
                name: "Cross Base",
                price: 11,
              },
              {
                name: "Cross Base + Ground Stake",
                price: 22.75,
              },
              {
                name: "Cross Base + Water Bag",
                price: 14.3,
              },
              {
                name: "Square Base",
                price: 13.65,
              },
            ],
            carryBag: [
              {
                name: "Yes",
                price: 15.0,
              },
              {
                name: "No",
                price: 0,
              },
            ],
          },
          {
            name: "Teardrop Flag",
            path: "/teardrop-flag",
            description: "Indoor and Outdoor bases. Single and Double Sided",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            flagOptions: [
              {
                name: "Flag + Pole",
                price: 28.6,
              },
              {
                name: "Flag Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: "Small 7ft",
                price: 32.99,
              },
              {
                name: "Medium 9ft",
                price: 32.99,
              },
              {
                name: "Large 11.2ft",
                price: 34.99,
              },
              {
                name: "X-Large 13.5ft",
                price: 46.99,
              },
            ],
            graphic: [
              {
                name: "Single Sided Print Thru",
                price: 0,
              },
              {
                name: "Single Sided Print Thru Reverse",
                price: 0,
              },
              {
                name: "Double Sided",
                price: 40,
              },
            ],
            base: [
              {
                name: "Ground Stake",
                price: 0,
              },
              {
                name: "Cross Base",
                price: 11,
              },
              {
                name: "Cross Base + Ground Stake",
                price: 22.75,
              },
              {
                name: "Cross Base + Water Bag",
                price: 14.3,
              },
              {
                name: "Square Base",
                price: 13.65,
              },
            ],
            carryBag: [
              {
                name: "Yes",
                price: 15.0,
              },
              {
                name: "No",
                price: 0,
              },
            ],
          },
          {
            name: "Rectangle Flag",
            path: "/rectangle-flag",
            description: "Indoor and Outdoor bases. Single and Double Sided",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            flagOptions: [
              {
                name: "Flag + Pole",
                price: 43.6,
              },
              {
                name: "Flag Only",
                price: 49.99,
              },
            ],
            graphic: [
              {
                name: "Single Sided Print Thru",
                price: 59.64,
              },
              {
                name: "Single Sided Print Thru Reverse",
                price: 59.64,
              },
              {
                name: "Double Sided",
                price: 63.59,
              },
            ],
            sizes: [
              {
                name: "Small 8.5ft",
                price: 93.59,
              },
              {
                name: "Medium 11.8ft",
                price: 103.99,
              },
              {
                name: "Large 15ft",
                price: 114.39,
              },
            ],
            base: [
              {
                name: "Ground Stake",
                price: 59.64,
              },
              {
                name: "Cross Base",
                price: 59.64,
              },
              {
                name: "Cross Base + Ground Stake",
                price: 63.59,
              },
              {
                name: "Cross Base + Water Bag",
                price: 79.89,
              },
              {
                name: "Square Base",
                price: 79.89,
              },
            ],
          },
          {
            name: "Econo Feather Flag",
            path: "/econo-feather",
            description:
              "Outdoor only - ground spike included. Single Sided graphic flutters in the wind",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            flagOptions: [
              {
                name: "Flag + Pole",
                price: 26.65,
              },
              {
                name: "Flag Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: "One Size 16ft",
                price: 69.0,
              },
            ],
            graphic: [
              {
                name: "Single Sided Print Thru",
                price: 59.64,
              },
              {
                name: "Single Sided Print Thru Reverse",
                price: 59.64,
              },
            ],
            base: [
              {
                name: "Ground Stake",
                price: 59.64,
              },
            ],
          },
          {
            name: "Custom Pole Flag",
            path: "/custom-pole-flag",
            description:
              "Custom Sizes + Full Color. Grommet Strips for pole installations",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            customSizing: true,
            flagOptions: [
              {
                name: "Flag + Pole",
                price: 26.65,
              },
              {
                name: "Flag Only",
                price: 0,
              },
            ],
            graphic: [
              {
                name: "Single Sided Print Thru",
                price: 59.64,
              },
              {
                name: "Double Sided",
                price: 59.64,
              },
            ],
            finishing: [
              {
                name: "Hem w/ Grommet Strip - Left",
                price: 59.64,
              },
              {
                name: "Hem w/ Grommet Strip - Top",
                price: 59.64,
              },
              {
                name: "Hem Only (No Grommets)",
                price: 59.64,
              },
            ],
            bracket: [
              {
                name: "Yes",
                price: 79.89,
              },
              {
                name: "No",
                price: 0,
              },
            ],
          },
          {
            name: "Econo Stock Flag",
            path: "/econo-stock",
            description: "Ready-to-Print designs",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            options: [
              {
                name: "All",
                price: 69.0,
              },
            ],
          },
        ],
      },
      {
        name: "Banner Stands",
        path: "/banner-stands",
        description:
          "Indoor vertical displays - great for in-store advertising and traveling exhibitors",
        products: [
          {
            name: "Tension Fabric Stand",
            path: "/tension-fabric",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Frame",
                price: 0,
              },
              {
                name: "Graphic Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: "36' x 90'",
                price: 129.99,
              },
              {
                name: "48' x 90'",
                price: 149.99,
              },
            ],
            graphic: [
              {
                name: "Single Sided",
                price: 129.99,
              },
              {
                name: "Double Sided",
                price: 149.99,
              },
            ],
          },
          {
            name: "Standard Retractable",
            path: "/standard-retractable",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Stand + Insert",
                price: 0,
              },
              {
                name: "Insert Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: "23' x 66'",
                price: 62.49,
              },
              {
                name: "24' x 81'",
                price: 65.0,
              },
              {
                name: "33' x 81'",
                price: 49.99,
              },
              {
                name: "33' x 81' (Black)",
                price: 49.99,
              },
              {
                name: "47' x 81'",
                price: 101.91,
              },
            ],
            insert: [
              {
                name: "UV Printed Fabric (Recommended)",
                price: 62.49,
              },
              {
                name: "UV Printed Matte Banner",
                price: 65.0,
              },
            ],
            LEDlight: [
              {
                name: "No",
                price: 0,
              },
              {
                name: "1 LED Light",
                price: 65.0,
              },
            ],
          },
          {
            name: "Deluxe Retractable",
            path: "/deluxe-retractable",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Stand + Insert",
                price: 0,
              },
              {
                name: "Insert Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: "33' x 81' Single Sided",
                price: 29.99,
              },
              {
                name: "33' x 81' Double Sided",
                price: 59.99,
              },
            ],
            insert: [
              {
                name: "UV Printed Fabric (Recommended)",
                price: 62.49,
              },
              {
                name: "UV Printed Matte Banner",
                price: 65.0,
              },
            ],
            LEDlight: [
              {
                name: "No",
                price: 0,
              },
              {
                name: "1 LED Light",
                price: 65.0,
              },
            ],
          },
          {
            name: "SD Retractable",
            path: "/sd-retractable",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Stand + Insert",
                price: 0,
              },
              {
                name: "Insert Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: `36" x 69"`,
                price: 29.99,
              },
              {
                name: `36" x 80"`,
                price: 29.99,
              },
              {
                name: `36" x 92"`,
                price: 29.99,
              },
              {
                name: `48" x 69"`,
                price: 29.99,
              },
              {
                name: `48" x 80"`,
                price: 29.99,
              },
              {
                name: `48" x 92"`,
                price: 29.99,
              },
              {
                name: `60" x 69"`,
                price: 29.99,
              },
              {
                name: `60" x 80"`,
                price: 29.99,
              },
              {
                name: `60" x 92"`,
                price: 29.99,
              },
            ],
            insert: [
              {
                name: "UV Printed Fabric (Recommended)",
                price: 62.49,
              },
              {
                name: "UV Printed Matte Banner",
                price: 65.0,
              },
            ],
            LEDlight: [
              {
                name: "No",
                price: 0,
              },
              {
                name: "1 LED Light",
                price: 65.0,
              },
            ],
          },
          {
            name: "X Stand",
            path: "/x-stand",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Stand + Graphic",
                price: 0,
              },
              {
                name: "Graphic Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: `24" x 63"`,
                price: 0,
              },
              {
                name: `32" x 71"`,
                price: 0,
              },
            ],
            graphic: [
              {
                name: "Matte Banner",
                price: 0,
              },
              {
                name: "Double White Popup",
                price: 0,
              },
            ],
          },
          {
            name: "Step and Repeat Backdrop",
            path: "/step-repeat-backdrop",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Frame",
                price: 0,
              },
              {
                name: "Graphic Only",
                price: 0,
              },
            ],
            sizes: [
              {
                name: `8' W x 8' H`,
                price: 0,
              },
              {
                name: `9' W x 8' H`,
                price: 0,
              },
              {
                name: `10' W x 8' H`,
                price: 0,
              },
            ],
            graphic: [
              {
                name: "9 oz. Fabric Banner",
                price: 0,
              },
            ],
          },
          {
            name: "Table Top Banner Stand",
            path: "/table-top-stand",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [
              {
                name: `11.5" x 17.5"`,
                price: 0,
              },
            ],
            insert: [
              {
                name: "Double White Popup",
                price: 0,
              },
            ],
          },
        ],
      },
      {
        name: "Real Estate Products",
        path: "/real-estate-products",
        description: "Outdoor signage designed to be long-lasting and reusable",
        products: [
          {
            name: "Yard Sign and H-Stake",
            path: "/yard-sign-h-stake",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Sign + H-Stake",
                price: 6.92,
              },
              {
                name: "Sign Only",
                price: 6.92,
              },
            ],
            sizes: [
              {
                name: `24" x 18" Horizontal Only`,
                price: 0,
              },
            ],
            graphic: [
              {
                name: `Single Sided`,
                price: 0,
              },
              {
                name: `Double Sided`,
                price: 0,
              },
            ],
            material: [
              {
                name: `4mm White Coroplast`,
                price: 0,
              },
            ],
          },
          {
            name: "Real Estate A-Frame",
            path: "/real-estate-a-frame",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Sign + Frame",
                price: 6.92,
              },
              {
                name: "Sign Only",
                price: 6.92,
              },
            ],
            sizes: [
              {
                name: `24" x 18"`,
                price: 0,
              },
            ],
            graphic: [
              {
                name: `Double Sided`,
                price: 0,
              },
            ],
            grommet: [
              {
                name: "Grommets (Top two corners)",
                price: 0,
              },
            ],
            material: [
              {
                name: `4mm White Coroplast`,
                price: 0,
              },
            ],
            rider: [
              { name: "Yes", price: 0 },
              { name: "No", price: 0 },
            ],
            pennantFlag: [
              { name: "Yes", price: 0 },
              { name: "No", price: 0 },
            ],
          },
          {
            name: "Real Estate Post",
            path: "/real-estate-post",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Post + Sign",
                price: 6.92,
              },
              {
                name: "Sign Only",
                price: 6.92,
              },
            ],
            sizes: [
              {
                name: `24" x 18"`,
                price: 0,
              },
            ],
            graphic: [
              {
                name: `Double Sided`,
                price: 0,
              },
            ],
            holesPunch: [{ name: "Included", price: 0 }],
            rider: [
              { name: "Yes", price: 0 },
              { name: "No", price: 0 },
            ],
          },
          {
            name: "Real Estate Frame",
            path: "/real-estate-frame",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Sign + Frame",
                price: 6.92,
              },
              {
                name: "Sign Only",
                price: 6.92,
              },
            ],
            sizes: [
              {
                name: `24" x 18"`,
                price: 0,
              },
            ],
            graphic: [
              {
                name: `Double Sided`,
                price: 0,
              },
            ],
            material: [{ name: "4mm White Coroplast", price: 0 }],
            rider: [
              { name: "Yes", price: 0 },
              { name: "No", price: 0 },
            ],
          },
        ],
      },
      {
        name: "A-Frame",
        path: "/a-frame",
        description: "Display systems for banners, signs and posters",
        products: [
          {
            name: "Banner A-Frame",
            path: "/banner-a-frame",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Banner + Frame",
                price: 109.89,
              },
              {
                name: "Banner Only",
                price: 109.89,
              },
            ],
            sizes: [
              {
                name: "4ft",
                price: 109.89,
              },
              {
                name: "8ft",
                price: 130.78,
              },
            ],
            banner: [
              {
                name: "1 piece",
                price: 109.89,
              },
              {
                name: "2 pieces",
                price: 109.89,
              },
            ],
            hem: [
              {
                name: "All Sides",
                price: 109.89,
              },
            ],
            material: [
              {
                name: "13oz. Matte Vinyl Banner",
                price: 109.89,
              },
            ],
            grommet: [
              {
                name: "4 Corner Only",
                price: 109.89,
              },
            ],
          },
          {
            name: "Snap Poster Hanger",
            path: "/snap-poster-hanger",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Hanger",
                price: 109.89,
              },
              {
                name: "Banner Only",
                price: 109.89,
              },
            ],
          },
          {
            name: "Poster Stand",
            path: "/poster-stand",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Stand + Graphic",
                price: 109.89,
              },
              {
                name: "Graphic Only",
                price: 109.89,
              },
            ],
            graphicSize: [
              {
                name: `22" x 28"`,
                price: 20.0,
              },
            ],
            graphic: [
              {
                name: `Double Sided`,
                price: 20.0,
              },
            ],
            material: [
              {
                name: `1/8" white PVC`,
                price: 74.99,
              },
            ],
          },
          {
            name: "Magnetic Wood Frame Hanger",
            path: "/magnetic-wood-frame-hanger",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [
              {
                name: `17.5" x 24"`,
                price: 11.99,
              },
              {
                name: `17.5" x 36"`,
                price: 13.99,
              },
            ],
            graphic: [
              {
                name: `Double White Popup`,
                price: 20.0,
              },
            ],
            hanger: [
              {
                name: "Wood With Magnet",
                price: 20.0,
              },
            ],
          },
        ],
      },
      {
        name: "Signicade A-Frame",
        path: "/signicade-a-frame",
        description:
          "The ultimate in sidewalk signage - Genuine Plasticade A-frames with full color UV prints",
        products: [
          {
            name: "Standard Signicade",
            path: "/standard-signicade",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + A-Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            graphic: [
              {
                name: "Mounted Vinyl - 1 piece",
                price: 94.99,
              },
              {
                name: "Mounted Vinyl - 2 pieces",
                price: 94.99,
              },
            ],
            frameColor: [
              {
                name: "White",
                price: 94.99,
              },
            ],
          },
          {
            name: "Deluxe Signicade",
            path: "/deluxe-signicade",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + A-Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            graphic: [
              {
                name: `24" x 36" Coroplast Sign - 1 pc`,
                price: 94.99,
              },
              {
                name: `24" x 36" Coroplast Signs - 2 pcs`,
                price: 94.99,
              },
            ],
            frameColor: [
              {
                name: "White",
                price: 94.99,
              },
            ],
          },
          {
            name: "Simposign II",
            path: "/simposign",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + A-Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            graphic: [
              {
                name: `22" x 28" Coroplast Sign - 1 pc`,
                price: 94.99,
              },
              {
                name: `22" x 28" Coroplast Signs - 2 pcs`,
                price: 94.99,
              },
            ],
            frameColor: [
              {
                name: "White",
                price: 94.99,
              },
            ],
          },
        ],
      },
      {
        name: "SEG Products",
        path: "/seg-products",
        description:
          "Silicone edged fabric graphics slot into aluminum frames for easy installation",
        products: [
          {
            name: "3ft SEG Display",
            path: "/3ft-seg-display",
            description: "Non-lit slim modern frame",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            displayOption: [
              {
                name: `Single Sided`,
                price: 94.99,
              },
              {
                name: `Double Sided`,
                price: 94.99,
              },
            ],
            sizes: [
              {
                name: "3ft Display",
                price: 94.99,
              },
            ],
          },
          {
            name: "10ft SEG Display",
            path: "/10ft-seg-display",
            description: "Non-lit slim modern frame",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            displayOption: [
              {
                name: `Single Sided`,
                price: 94.99,
              },
              {
                name: `Double Sided`,
                price: 94.99,
              },
            ],
            sizes: [
              {
                name: "10ft Display",
                price: 94.99,
              },
            ],
          },
          {
            name: "20ft SEG Display",
            path: "/20ft-seg-display",
            description: "Non-lit slim modern frame",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            displayOption: [
              {
                name: `Single Sided`,
                price: 94.99,
              },
              {
                name: `Double Sided`,
                price: 94.99,
              },
            ],
            sizes: [
              {
                name: "20ft Display",
                price: 94.99,
              },
            ],
          },
          {
            name: "10ft SEG Backlit Display",
            path: "/10ft-seg-backlit-display",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            displayOption: [
              {
                name: `Single Sided`,
                price: 94.99,
              },
              {
                name: `Double Sided`,
                price: 94.99,
              },
            ],
            sizes: [
              {
                name: "10ft Display",
                price: 94.99,
              },
            ],
          },
          {
            name: "8ft SEG Backlit Popup Display",
            path: "/8ft-seg-backlit-popup",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            displayOption: [
              {
                name: `Single Sided`,
                price: 94.99,
              },
              {
                name: `Double Sided`,
                price: 94.99,
              },
            ],
            sizes: [
              {
                name: "8ft Display",
                price: 94.99,
              },
            ],
          },
          {
            name: "10ft SEG Backlit Popup Display",
            path: "/10ft-seg-backlit-popup",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              {
                name: "Graphic + Frame",
                price: 94.99,
              },
              {
                name: "Graphic Only",
                price: 94.99,
              },
            ],
            displayOption: [
              {
                name: `Single Sided`,
                price: 94.99,
              },
              {
                name: `Double Sided`,
                price: 94.99,
              },
            ],
            sizes: [
              {
                name: "10ft Display",
                price: 94.99,
              },
            ],
          },
        ],
      },
      // {
      //   name: "Trade Show Products",
      //   path: "/trade-show-products",
      //   products: [{}],
      // },
      // { name: "Custom Event Tent", path: "/custom-event-tent", products: [{}] },
      // { name: "Table Throw", path: "/table-throw", products: [{}] },
      // { name: "Hardware Only", path: "/hardware", products: [{}] },
    ],
  },
  {
    name: "Banners",
    url: "/banners",
    description:
      "Indoor vertical displays - great for in-store advertising and traveling exhibitors",
    subCategories: [
      {
        name: "All",
        path: "/all",
        description: "",
      },
    ],
    products: [
      {
        name: "13oz Vinyl Banner",
        path: "/13oz-vinyl-banner",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        sizes: [
          {
            name: "36'x90'",
            price: 129.99,
          },
          {
            name: "48'x90'",
            price: 149.99,
          },
        ],
        numOfSides: [
          {
            name: "1 Side",
            price: 0,
          },
          {
            name: "2 Sides",
            price: 0,
          },
        ],
        material: [
          {
            name: "13oz. Matte Vinyl Banner",
            price: 0,
          },
        ],
        hem: [
          {
            name: "All Sides",
            price: 0,
          },
          {
            name: "No Hem",
            price: 0,
          },
        ],
        grommet: [
          {
            name: "No Grommet",
            price: 0,
          },
          {
            name: "Every 2' All Sides",
            price: 0,
          },
          {
            name: "Every 2' Top & Bottom",
            price: 0,
          },
          {
            name: "Every 2' Left & Right",
            price: 0,
          },
          {
            name: "4 Corner Only",
            price: 0,
          },
        ],
        polePocket: [
          {
            name: "No Pole Pockets",
            price: 0,
          },
          {
            name: `2" - Top and Bottom`,
            price: 0,
          },
          {
            name: `3" - Top and Bottom`,
            price: 0,
          },
          {
            name: `4" - Top and Bottom`,
            price: 0,
          },
          {
            name: `2" - Top Only`,
            price: 0,
          },
          {
            name: `3" - Top Only`,
            price: 0,
          },
          {
            name: `4" - Top Only`,
            price: 0,
          },
        ],
        windslit: [
          {
            name: `No Windslits`,
            price: 0,
          },
          {
            name: `Standard Windslits`,
            price: 0,
          },
        ],
        webbing: [
          {
            name: `No Webbing, No D-rings`,
            price: 0,
          },
          {
            name: `1" Webbing`,
            price: 0,
          },
          {
            name: `1" Webbing w/ D-rings`,
            price: 0,
          },
          {
            name: `1" Velcro - All Sides`,
            price: 0,
          },
        ],
        corners: [
          {
            name: `No Reinforced Corners`,
            price: 0,
          },
          {
            name: `Reinforce Top Only`,
            price: 0,
          },
          {
            name: `Reinforce Bottom Only`,
            price: 0,
          },
          {
            name: `Reinforce All Corners`,
            price: 0,
          },
        ],
        rope: [
          {
            name: `No Rope`,
            price: 0,
          },
          {
            name: `3/16" - Top Only`,
            price: 0,
          },
          {
            name: `3/16" - Bottom Only`,
            price: 0,
          },
          {
            name: `3/16" - Top and Bottom`,
            price: 0,
          },
          {
            name: `5/16" - Top Only`,
            price: 0,
          },
          {
            name: `5/16" - Bottom Only`,
            price: 0,
          },
          {
            name: `5/16" - Top and Bottom`,
            price: 0,
          },
        ],
      },
      {
        name: "18oz Blockout Banner",
        path: "/18oz-blockout-banner",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        numOfSides: [
          {
            name: "1 Side",
            price: 0,
          },
          {
            name: "2 Sides",
            price: 0,
          },
        ],
        material: [
          {
            name: "18oz. Blockout Vinyl Banner",
            price: 0,
          },
        ],
        polePocket: [
          {
            name: "No Pole Pockets",
            price: 0,
          },
          {
            name: `2" - Top and Bottom`,
            price: 0,
          },
          {
            name: `3" - Top and Bottom`,
            price: 0,
          },
          {
            name: `4" - Top and Bottom`,
            price: 0,
          },
          {
            name: `2" - Top Only`,
            price: 0,
          },
          {
            name: `3" - Top Only`,
            price: 0,
          },
          {
            name: `4" - Top Only`,
            price: 0,
          },
        ],
        hem: [
          {
            name: "All Sides",
            price: 0,
          },
          {
            name: "No Hem",
            price: 0,
          },
        ],
        grommet: [
          {
            name: "No Grommet",
            price: 0,
          },
          {
            name: "Every 2' All Sides",
            price: 0,
          },
          {
            name: "Every 2' Top & Bottom",
            price: 0,
          },
          {
            name: "Every 2' Left & Right",
            price: 0,
          },
          {
            name: "4 Corner Only",
            price: 0,
          },
        ],
        windslit: [
          {
            name: `No Windslits`,
            price: 0,
          },
          {
            name: `Standard Windslits`,
            price: 0,
          },
        ],
        webbing: [
          {
            name: `No Webbing, No D-rings`,
            price: 0,
          },
          {
            name: `1" Webbing`,
            price: 0,
          },
          {
            name: `1" Webbing w/ D-rings`,
            price: 0,
          },
          {
            name: `1" Velcro - All Sides`,
            price: 0,
          },
        ],
        corners: [
          {
            name: `No Reinforced Corners`,
            price: 0,
          },
          {
            name: `Reinforce Top Only`,
            price: 0,
          },
          {
            name: `Reinforce Bottom Only`,
            price: 0,
          },
          {
            name: `Reinforce All Corners`,
            price: 0,
          },
        ],
        rope: [
          {
            name: `No Rope`,
            price: 0,
          },
          {
            name: `3/16" - Top Only`,
            price: 0,
          },
          {
            name: `3/16" - Bottom Only`,
            price: 0,
          },
          {
            name: `3/16" - Top and Bottom`,
            price: 0,
          },
          {
            name: `5/16" - Top Only`,
            price: 0,
          },
          {
            name: `5/16" - Bottom Only`,
            price: 0,
          },
          {
            name: `5/16" - Top and Bottom`,
            price: 0,
          },
        ],
      },
      {
        name: "Backlit Banner",
        path: "/backlit-banner",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        numOfSides: [
          {
            name: "1 Side",
            price: 0,
          },
          {
            name: "2 Sides",
            price: 0,
          },
        ],
        material: [
          {
            name: "15oz. Vinyl Banner(backlit)",
            price: 0,
          },
        ],
        colorProfile: [
          {
            name: "Double Layer print (Night-time use)",
            price: 0,
          },
          {
            name: `Single Layer print (Day-time use)`,
            price: 0,
          },
        ],
        hem: [
          {
            name: "All Sides",
            price: 0,
          },
          {
            name: "No Hem",
            price: 0,
          },
        ],
        grommet: [
          {
            name: "No Grommet",
            price: 0,
          },
          {
            name: "Every 2' All Sides",
            price: 0,
          },
          {
            name: "Every 2' Top & Bottom",
            price: 0,
          },
          {
            name: "Every 2' Left & Right",
            price: 0,
          },
          {
            name: "4 Corner Only",
            price: 0,
          },
        ],
        webbing: [
          {
            name: `No Webbing, No D-rings`,
            price: 0,
          },
          {
            name: `1" Webbing`,
            price: 0,
          },
          {
            name: `1" Webbing w/ D-rings`,
            price: 0,
          },
          {
            name: `1" Velcro - All Sides`,
            price: 0,
          },
        ],
      },
      {
        name: "Mesh Banner",
        path: "/mesh-banner",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        numOfSides: [
          {
            name: "1 Side",
            price: 0,
          },
          {
            name: "2 Sides",
            price: 0,
          },
        ],
        polePocket: [
          {
            name: "No Pole Pockets",
            price: 0,
          },
          {
            name: `2" - Top and Bottom`,
            price: 0,
          },
          {
            name: `3" - Top and Bottom`,
            price: 0,
          },
          {
            name: `4" - Top and Bottom`,
            price: 0,
          },
          {
            name: `2" - Top Only`,
            price: 0,
          },
          {
            name: `3" - Top Only`,
            price: 0,
          },
          {
            name: `4" - Top Only`,
            price: 0,
          },
        ],
        hem: [
          {
            name: "All Sides",
            price: 0,
          },
          {
            name: "No Hem",
            price: 0,
          },
        ],
        grommet: [
          {
            name: "No Grommet",
            price: 0,
          },
          {
            name: "Every 2' All Sides",
            price: 0,
          },
          {
            name: "Every 2' Top & Bottom",
            price: 0,
          },
          {
            name: "Every 2' Left & Right",
            price: 0,
          },
          {
            name: "4 Corner Only",
            price: 0,
          },
        ],
        webbing: [
          {
            name: `No Webbing, No D-rings`,
            price: 0,
          },
          {
            name: `1" Webbing`,
            price: 0,
          },
          {
            name: `1" Webbing w/ D-rings`,
            price: 0,
          },
          {
            name: `1" Velcro - All Sides`,
            price: 0,
          },
        ],
        corners: [
          {
            name: `No Reinforced Corners`,
            price: 0,
          },
          {
            name: `Reinforce Top Only`,
            price: 0,
          },
          {
            name: `Reinforce Bottom Only`,
            price: 0,
          },
          {
            name: `Reinforce All Corners`,
            price: 0,
          },
        ],
        rope: [
          {
            name: `No Rope`,
            price: 0,
          },
          {
            name: `3/16" - Top Only`,
            price: 0,
          },
          {
            name: `3/16" - Bottom Only`,
            price: 0,
          },
          {
            name: `3/16" - Top and Bottom`,
            price: 0,
          },
          {
            name: `5/16" - Top Only`,
            price: 0,
          },
          {
            name: `5/16" - Bottom Only`,
            price: 0,
          },
          {
            name: `5/16" - Top and Bottom`,
            price: 0,
          },
        ],
      },
      {
        name: "Indoor Banner / Super Smooth",
        path: "/indoor-banner",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        material: [
          {
            name: "14mil Double White Popup",
            price: 0,
          },
        ],
        grommet: [
          {
            name: "No Grommet",
            price: 0,
          },
          {
            name: "Every 2' All Sides",
            price: 0,
          },
          {
            name: "Every 2' Top & Bottom",
            price: 0,
          },
          {
            name: "Every 2' Left & Right",
            price: 0,
          },
          {
            name: "4 Corner Only",
            price: 0,
          },
        ],
      },
      {
        name: "Pole Banner",
        path: "/pole-banner",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        bannerOptions: [
          {
            name: "Banner + Hardware",
            price: 0,
          },
          {
            name: "Banner Only",
            price: 0,
          },
        ],
        sizes: [
          { name: `18" x 24`, price: 0 },
          { name: `18" x 25"`, price: 0 },
          { name: `18" x 26"`, price: 0 },
          { name: `18" x 27"`, price: 0 },
          { name: `18" x 28"`, price: 0 },
          { name: `18" x 29"`, price: 0 },
          { name: `18" x 30"`, price: 0 },
          { name: `18" x 31"`, price: 0 },
          { name: `18" x 32"`, price: 0 },
          { name: `18" x 33"`, price: 0 },
          { name: `18" x 34"`, price: 0 },
          { name: `18" x 35"`, price: 0 },
          { name: `18" x 36"`, price: 0 },
          { name: `18" x 37"`, price: 0 },
          { name: `18" x 38"`, price: 0 },
          { name: `18" x 39"`, price: 0 },
          { name: `18" x 40"`, price: 0 },
          { name: `18" x 41"`, price: 0 },
          { name: `18" x 42"`, price: 0 },
          { name: `18" x 43"`, price: 0 },
          { name: `18" x 44"`, price: 0 },
          { name: `18" x 45"`, price: 0 },
          { name: `18" x 46"`, price: 0 },
          { name: `18" x 47"`, price: 0 },
          { name: `18" x 48"`, price: 0 },
          { name: `18" x 49"`, price: 0 },
          { name: `18" x 50"`, price: 0 },
          { name: `18" x 51"`, price: 0 },
          { name: `18" x 52"`, price: 0 },
          { name: `18" x 53"`, price: 0 },
          { name: `18" x 54"`, price: 0 },
          { name: `18" x 55"`, price: 0 },
          { name: `18" x 56"`, price: 0 },
          { name: `18" x 57"`, price: 0 },
          { name: `18" x 58"`, price: 0 },
          { name: `18" x 59"`, price: 0 },
          { name: `18" x 60"`, price: 0 },
          { name: `18" x 61"`, price: 0 },
          { name: `18" x 62"`, price: 0 },
          { name: `18" x 63"`, price: 0 },
          { name: `18" x 64"`, price: 0 },
          { name: `18" x 65"`, price: 0 },
          { name: `18" x 66"`, price: 0 },
          { name: `18" x 67"`, price: 0 },
          { name: `18" x 68"`, price: 0 },
          { name: `18" x 69"`, price: 0 },
          { name: `18" x 70"`, price: 0 },
          { name: `18" x 71"`, price: 0 },
          { name: `18" x 72"`, price: 0 },
          { name: `18" x 73"`, price: 0 },
          { name: `18" x 74"`, price: 0 },
          { name: `18" x 75"`, price: 0 },
          { name: `18" x 76"`, price: 0 },
          { name: `18" x 77"`, price: 0 },
          { name: `18" x 78"`, price: 0 },
          { name: `18" x 79"`, price: 0 },
          { name: `18" x 80"`, price: 0 },
          { name: `18" x 81"`, price: 0 },
          { name: `18" x 82"`, price: 0 },
          { name: `18" x 83"`, price: 0 },
          { name: `18" x 84"`, price: 0 },
          { name: `18" x 85"`, price: 0 },
          { name: `18" x 86"`, price: 0 },
          { name: `18" x 87"`, price: 0 },
          { name: `18" x 88"`, price: 0 },
          { name: `18" x 89"`, price: 0 },
          { name: `18" x 90"`, price: 0 },
          { name: `18" x 91"`, price: 0 },
          { name: `18" x 92"`, price: 0 },
          { name: `18" x 93"`, price: 0 },
          { name: `18" x 94"`, price: 0 },
          { name: `18" x 95"`, price: 0 },
          { name: `18" x 96"`, price: 0 },
          { name: `24" x 24"`, price: 0 },
          { name: `24" x 25"`, price: 0 },
          { name: `24" x 26"`, price: 0 },
          { name: `24" x 27"`, price: 0 },
          { name: `24" x 28"`, price: 0 },
          { name: `24" x 29"`, price: 0 },
          { name: `24" x 30"`, price: 0 },
          { name: `24" x 31"`, price: 0 },
          { name: `24" x 32"`, price: 0 },
          { name: `24" x 33"`, price: 0 },
          { name: `24" x 34"`, price: 0 },
          { name: `24" x 36"`, price: 0 },
          { name: `24" x 37"`, price: 0 },
          { name: `24" x 38"`, price: 0 },
          { name: `24" x 39"`, price: 0 },
          { name: `24" x 40"`, price: 0 },
          { name: `24" x 41"`, price: 0 },
          { name: `24" x 42"`, price: 0 },
          { name: `24" x 43"`, price: 0 },
          { name: `24" x 44"`, price: 0 },
          { name: `24" x 45"`, price: 0 },
          { name: `24" x 46"`, price: 0 },
          { name: `24" x 47"`, price: 0 },
          { name: `24" x 48"`, price: 0 },
          { name: `24" x 49"`, price: 0 },
          { name: `24" x 50"`, price: 0 },
          { name: `24" x 51"`, price: 0 },
          { name: `24" x 52"`, price: 0 },
          { name: `24" x 53"`, price: 0 },
          { name: `24" x 54"`, price: 0 },
          { name: `24" x 55"`, price: 0 },
          { name: `24" x 56"`, price: 0 },
          { name: `24" x 57"`, price: 0 },
          { name: `24" x 58"`, price: 0 },
          { name: `24" x 59"`, price: 0 },
          { name: `24" x 60"`, price: 0 },
          { name: `24" x 61"`, price: 0 },
          { name: `24" x 62"`, price: 0 },
          { name: `24" x 63"`, price: 0 },
          { name: `24" x 64"`, price: 0 },
          { name: `24" x 65"`, price: 0 },
          { name: `24" x 66"`, price: 0 },
          { name: `24" x 67"`, price: 0 },
          { name: `24" x 68"`, price: 0 },
          { name: `24" x 69"`, price: 0 },
          { name: `24" x 70"`, price: 0 },
          { name: `24" x 71"`, price: 0 },
          { name: `24" x 72"`, price: 0 },
          { name: `24" x 73"`, price: 0 },
          { name: `24" x 74"`, price: 0 },
          { name: `24" x 75"`, price: 0 },
          { name: `24" x 76"`, price: 0 },
          { name: `24" x 77"`, price: 0 },
          { name: `24" x 78"`, price: 0 },
          { name: `24" x 79"`, price: 0 },
          { name: `24" x 80"`, price: 0 },
          { name: `24" x 81"`, price: 0 },
          { name: `24" x 82"`, price: 0 },
          { name: `24" x 83"`, price: 0 },
          { name: `24" x 84"`, price: 0 },
          { name: `24" x 85"`, price: 0 },
          { name: `24" x 86"`, price: 0 },
          { name: `24" x 87"`, price: 0 },
          { name: `24" x 88"`, price: 0 },
          { name: `24" x 89"`, price: 0 },
          { name: `24" x 90"`, price: 0 },
          { name: `24" x 91"`, price: 0 },
          { name: `24" x 92"`, price: 0 },
          { name: `24" x 93"`, price: 0 },
          { name: `24" x 94"`, price: 0 },
          { name: `24" x 95"`, price: 0 },
          { name: `24" x 96"`, price: 0 },
          { name: `30" x 24"`, price: 0 },
          { name: `30" x 25"`, price: 0 },
          { name: `30" x 26"`, price: 0 },
          { name: `30" x 27"`, price: 0 },
          { name: `30" x 28"`, price: 0 },
          { name: `30" x 29"`, price: 0 },
          { name: `30" x 30"`, price: 0 },
          { name: `30" x 31"`, price: 0 },
          { name: `30" x 32"`, price: 0 },
          { name: `30" x 33"`, price: 0 },
          { name: `30" x 34"`, price: 0 },
          { name: `30" x 35"`, price: 0 },
          { name: `30" x 36"`, price: 0 },
          { name: `30" x 37"`, price: 0 },
          { name: `30" x 38"`, price: 0 },
          { name: `30" x 39"`, price: 0 },
          { name: `30" x 40"`, price: 0 },
          { name: `30" x 41"`, price: 0 },
          { name: `30" x 42"`, price: 0 },
          { name: `30" x 43"`, price: 0 },
          { name: `30" x 44"`, price: 0 },
          { name: `30" x 45"`, price: 0 },
          { name: `30" x 46"`, price: 0 },
          { name: `30" x 47"`, price: 0 },
          { name: `30" x 48"`, price: 0 },
          { name: `30" x 49"`, price: 0 },
          { name: `30" x 50"`, price: 0 },
          { name: `30" x 51"`, price: 0 },
          { name: `30" x 52"`, price: 0 },
          { name: `30" x 53"`, price: 0 },
          { name: `30" x 54"`, price: 0 },
          { name: `30" x 55"`, price: 0 },
          { name: `30" x 56"`, price: 0 },
          { name: `30" x 57"`, price: 0 },
          { name: `30" x 58"`, price: 0 },
          { name: `30" x 59"`, price: 0 },
          { name: `30" x 60"`, price: 0 },
          { name: `30" x 61"`, price: 0 },
          { name: `30" x 62"`, price: 0 },
          { name: `30" x 63"`, price: 0 },
          { name: `30" x 64"`, price: 0 },
          { name: `30" x 65"`, price: 0 },
          { name: `30" x 66"`, price: 0 },
          { name: `30" x 67"`, price: 0 },
          { name: `30" x 68"`, price: 0 },
          { name: `30" x 69"`, price: 0 },
          { name: `30" x 70"`, price: 0 },
          { name: `30" x 71"`, price: 0 },
          { name: `30" x 72"`, price: 0 },
          { name: `30" x 73"`, price: 0 },
          { name: `30" x 74"`, price: 0 },
          { name: `30" x 75"`, price: 0 },
          { name: `30" x 76"`, price: 0 },
          { name: `30" x 77"`, price: 0 },
          { name: `30" x 78"`, price: 0 },
          { name: `30" x 79"`, price: 0 },
          { name: `30" x 80"`, price: 0 },
          { name: `30" x 81"`, price: 0 },
          { name: `30" x 82"`, price: 0 },
          { name: `30" x 83"`, price: 0 },
          { name: `30" x 84"`, price: 0 },
          { name: `30" x 85"`, price: 0 },
          { name: `30" x 86"`, price: 0 },
          { name: `30" x 87"`, price: 0 },
          { name: `30" x 88"`, price: 0 },
          { name: `30" x 89"`, price: 0 },
          { name: `30" x 90"`, price: 0 },
          { name: `30" x 91"`, price: 0 },
          { name: `30" x 92"`, price: 0 },
          { name: `30" x 93"`, price: 0 },
          { name: `30" x 94"`, price: 0 },
          { name: `30" x 95"`, price: 0 },
          { name: `30" x 96"`, price: 0 },
        ],
        graphic: [{ name: "Double Sided", price: 0 }],
        material: [{ name: "18oz. Blockout Vinyl Banner", price: 0 }],
        polePocket: [{ name: `2" - Top and Bottom`, price: 0 }],
        grommet: [{ name: `4 Corner Only`, price: 0 }],
        windslit: [
          { name: `No Windslits`, price: 0 },
          { name: `1`, price: 0 },
          { name: `2`, price: 0 },
          { name: `3`, price: 0 },
          { name: `4`, price: 0 },
          { name: `5`, price: 0 },
          { name: `6`, price: 0 },
          { name: `7`, price: 0 },
          { name: `8`, price: 0 },
          { name: `9`, price: 0 },
          { name: `10`, price: 0 },
        ],
      },
      {
        name: "Fabric Banner (9oz. Wrinkle Free)",
        path: "/fabric-banner-9oz",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        material: [{ name: "9oz. Wrinkle Free Fabric", price: 0 }],
        polePocket: [
          {
            name: "No Pole Pockets",
            price: 0,
          },
          {
            name: `2" - Top and Bottom`,
            price: 0,
          },
          {
            name: `3" - Top and Bottom`,
            price: 0,
          },
          {
            name: `4" - Top and Bottom`,
            price: 0,
          },
          {
            name: `2" - Top Only`,
            price: 0,
          },
          {
            name: `3" - Top Only`,
            price: 0,
          },
          {
            name: `4" - Top Only`,
            price: 0,
          },
        ],
        hem: [
          {
            name: "All Sides",
            price: 0,
          },
          {
            name: "No Hem",
            price: 0,
          },
        ],
        grommet: [
          {
            name: "No Grommet",
            price: 0,
          },
          {
            name: "Every 2' All Sides",
            price: 0,
          },
          {
            name: "Every 2' Top & Bottom",
            price: 0,
          },
          {
            name: "Every 2' Left & Right",
            price: 0,
          },
          {
            name: "4 Corner Only",
            price: 0,
          },
        ],
        velcro: [
          {
            name: "No Velcro",
            price: 0,
          },
          {
            name: `1" Velcro - All Sides`,
            price: 0,
          },
          {
            name: `2" Velcro - All Sides`,
            price: 0,
          },
        ],
      },
      {
        name: "Fabric Banner (9.5oz. Blockout)",
        path: "/fabric-banner-9.5oz-blockout",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        material: [{ name: "9.5oz Block Out Fabric", price: 0 }],
        polePocket: [
          {
            name: "No Pole Pockets",
            price: 0,
          },
          {
            name: `2" - Top and Bottom`,
            price: 0,
          },
          {
            name: `3" - Top and Bottom`,
            price: 0,
          },
          {
            name: `4" - Top and Bottom`,
            price: 0,
          },
          {
            name: `2" - Top Only`,
            price: 0,
          },
          {
            name: `3" - Top Only`,
            price: 0,
          },
          {
            name: `4" - Top Only`,
            price: 0,
          },
        ],
        hem: [
          {
            name: "All Sides",
            price: 0,
          },
          {
            name: "No Hem",
            price: 0,
          },
        ],
        grommet: [
          {
            name: "No Grommet",
            price: 0,
          },
          {
            name: "Every 2' All Sides",
            price: 0,
          },
          {
            name: "Every 2' Top & Bottom",
            price: 0,
          },
          {
            name: "Every 2' Left & Right",
            price: 0,
          },
          {
            name: "4 Corner Only",
            price: 0,
          },
        ],
        velcro: [
          {
            name: "No Velcro",
            price: 0,
          },
          {
            name: `1" Velcro - All Sides`,
            price: 0,
          },
          {
            name: `2" Velcro - All Sides`,
            price: 0,
          },
        ],
      },
      {
        name: "Tension Fabric",
        path: "/tension-fabric",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
        material: [{ name: "8.8oz. Tension Fabric", price: 0 }],
        polePocket: [
          {
            name: "No Pole Pockets",
            price: 0,
          },
          {
            name: `2" - Top and Bottom`,
            price: 0,
          },
          {
            name: `3" - Top and Bottom`,
            price: 0,
          },
          {
            name: `4" - Top and Bottom`,
            price: 0,
          },
          {
            name: `2" - Top Only`,
            price: 0,
          },
          {
            name: `3" - Top Only`,
            price: 0,
          },
          {
            name: `4" - Top Only`,
            price: 0,
          },
        ],
        hem: [
          {
            name: "All Sides",
            price: 0,
          },
          {
            name: "No Hem",
            price: 0,
          },
        ],
        grommet: [
          {
            name: "No Grommet",
            price: 0,
          },
          {
            name: "Every 2' All Sides",
            price: 0,
          },
          {
            name: "Every 2' Top & Bottom",
            price: 0,
          },
          {
            name: "Every 2' Left & Right",
            price: 0,
          },
          {
            name: "4 Corner Only",
            price: 0,
          },
        ],
        velcro: [
          {
            name: "No Velcro",
            price: 0,
          },
          {
            name: `1" Velcro - All Sides`,
            price: 0,
          },
          {
            name: `2" Velcro - All Sides`,
            price: 0,
          },
        ],
      },
    ],
  },
  {
    name: "Large Format",
    url: "/large-format",
    subCategories: [
      {
        name: "Wall Art",
        path: "/wall-art",
        description: "",
        products: [
          {
            name: "Canvas Wrap",
            path: "/canvas-wrap",
            description: `1.5" thick gallery wrap`,
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [
              { name: `8" x 10"`, price: 9.11 },
              { name: `11" x 14"`, price: 9.11 },
              { name: `12" x 16"`, price: 9.11 },
              { name: `12" x 18"`, price: 9.11 },
              { name: `16" x 20"`, price: 9.11 },
              { name: `16" x 24"`, price: 9.11 },
              { name: `18" x 24"`, price: 9.11 },
              { name: `24" x 36"`, price: 9.11 },
              { name: `32" x 48"`, price: 9.11 },
              { name: `12" x 12"`, price: 9.11 },
              { name: `16" x 16"`, price: 9.11 },
              { name: `24" x 24"`, price: 9.11 },
            ],
            edgeOption: [
              {
                name: "Mirror Image",
                price: 0,
              },
              {
                name: "Fold",
                price: 0,
              },
              {
                name: "Black",
                price: 0,
              },
              {
                name: "White",
                price: 0,
              },
            ],
          },
          {
            name: "Framed Canvas",
            path: "/framed-canvas",
            description: "Frames available in black, off white and pecan",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [
              { name: `16" x 16"`, price: 9.11 },
              { name: `16" x 24"`, price: 9.11 },
              { name: `24" x 24"`, price: 9.11 },
              { name: `24" x 36"`, price: 9.11 },
            ],
            frameColor: [
              {
                name: "Pecan",
                price: 0,
              },
              {
                name: "Black",
                price: 0,
              },
              {
                name: "White",
                price: 0,
              },
            ],
          },
          {
            name: "Framed Poster",
            path: "/framed-poster",
            description: "Black frame with mat board and plexiglass",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [{ name: `16" x 24"`, price: 9.11 }],
            frameColor: [
              {
                name: "Black",
                price: 0,
              },
            ],
          },
          {
            name: "Acrylic Prints",
            path: "/acrylic-prints",
            description: `Back mounted graphic with 1" Standoff hardware`,
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [
              { name: `16" x 24"`, price: 9.11 },
              { name: `20" x 30"`, price: 9.11 },
              { name: `24" x 36"`, price: 9.11 },
            ],
            acrylic: [
              {
                name: `1/4" Plexiglass`,
                price: 0,
              },
            ],
            standOff: [
              {
                name: `Included`,
                price: 0,
              },
            ],
          },
          {
            name: "Magnetic Wood Frame Hanger",
            path: "/wood-frame",
            description: "Snap-to-hang solid wood hangers",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [
              { name: `17.5"W x 24"H`, price: 9.11 },
              { name: `17.5"W x 36"H`, price: 9.11 },
            ],
            graphic: [
              {
                name: `Double White Popup`,
                price: 0,
              },
            ],
            hanger: [
              {
                name: `Wood With Magnet`,
                price: 0,
              },
            ],
          },
          {
            name: "Framed Prints",
            path: "/framed-prints",
            description: "Eco-friendly natural wood styling",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [
              { name: `16" x 20"`, price: 9.11 },
              { name: `18" x 24"`, price: 9.11 },
              { name: `24" x 32"`, price: 9.11 },
            ],
            frame: [
              {
                name: `Wood`,
                price: 0,
              },
            ],
          },
          {
            name: "Canvas Roll",
            path: "/canvas-roll",
            description: "15 mil. semi­gloss artist canvas",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [
              {
                name: `15mil. White Canvas`,
                price: 4.7,
              },
            ],
          },
        ],
      },
      {
        name: "Adhesive Products",
        path: "/adhesive-products",
        description: "",
        products: [
          {
            name: "Adhesive Vinyl",
            path: "/adhesive-vinyl",
            description:
              "4mil white PVC calendar film. Permanent adhesive for indoor and outdoor use",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "4mil. Permanent Adhesive", price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
            lamination: [{ name: "No", price: 1.5 }],
          },
          {
            name: "High Performance Vinyl",
            path: "/performance-vinyl",
            description:
              "3mil ultra-calendared PVC film. Initial low tack for repositioning. For long term installs on flat or simple curves",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "3mil Adhesive Vinyl", price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
            lamination: [{ name: "No", price: 1.5 }],
          },
          {
            name: "3M IJ-180Cv3 Controltac (Vehicle Wrap)",
            path: "/vehicle-wrap",
            description:
              "2mil Cast vinyl + overlaminate. Long term, pressure activated vehicle wrap",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "3M IJ 180Cv3", price: 1.5 }],
            print: [{ name: "Eco Solvent", price: 1.5 }],
            lamination: [{ name: "Gloss 3M Scotchcal 8518", price: 1.5 }],
          },
          {
            name: "Adhesive Window Perf",
            path: "/adhesive-window-perf",
            description:
              "30% perforated window film. One-way visibility, black adhesive backing",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "70/30 Perforated Adhesive", price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
            lamination: [{ name: "No", price: 1.5 }],
          },
          {
            name: "Premium Window Cling",
            path: "/window-cling",
            description:
              "Premium cling material with water-free installation. Easy to reposition and reuse",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "Premium Window Cling", price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "Adhesive Wall Fabric",
            path: "/adhesive-wall-fabric",
            description:
              "Designed for interior walls. Never falls down. Low tack - easy to remove and reposition",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "Low Tack Adhesive Fabric", price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "Adhesive Clear Vinyl",
            path: "/adhesive-clear-vinyl",
            description:
              "Crystal clear vinyl makes CMYK prints semi-transparent. Allows for light pass through",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            colorProfile: [
              { name: "Single Layer print (Day-time use)", price: 1.5 },
              { name: "Double Layer print (Night-time use)", price: 1.5 },
            ],
            material: [{ name: "Ultra Clear Vinyl", price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
            lamination: [{ name: "No", price: 1.5 }],
          },
          {
            name: "Adhesive Translucent Vinyl",
            path: "/adhesive-translucent-vinyl",
            description:
              "3mil illumination film. Laminated for outdoor protection. Optional double layer print",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            colorProfile: [
              { name: "Double Layer print (Night-time use)", price: 1.5 },
              { name: "Single Layer print (Day-time use)", price: 1.5 },
            ],
            material: [
              { name: "3mil. Matte Translucent Adhesive", price: 1.5 },
            ],
            print: [{ name: "Eco Solvent", price: 1.5 }],
            lamination: [{ name: "2.5mil Matte Lamination", price: 1.5 }],
          },
          {
            name: "Floor Graphics",
            path: "/floor-graphics",
            description:
              "7mil non-slip floor lamination. Easy installation and clean removal",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "Floor Vinyl - Mess Free Removal", price: 1.5 }],
            lamination: [{ name: "Anti-slip Lamination", price: 1.5 }],
          },
          {
            name: "Frosted Vinyl",
            path: "/frosted-vinyl",
            description:
              "Full color print on frosted vinyl. Allows for privacy when installed on windows",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "Privacy Film Vinyl", price: 1.5 }],
            lamination: [{ name: "Included", price: 1.5 }],
          },
          {
            name: "Reflective Adhesive Vinyl",
            path: "/reflective-adhesive-vinyl",
            description:
              "4mil reflective permanent adhesive. Highly visible metallic surface",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [
              { name: "4 mil. Engineer Grade Reflective Vinyl", price: 1.5 },
            ],
            print: [{ name: "UV Ink", price: 1.5 }],
            lamination: [{ name: "No", price: 1.5 }],
          },
          {
            name: "Dry Erase Adhesive Vinyl",
            path: "/dry-erase-adhesive-vinyl",
            description: "4mil white PVC calendar film with dry erase coating",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            material: [{ name: "4mil. Permanent Adhesive", price: 1.5 }],
            lamination: [{ name: "Dry Erase Coating", price: 1.5 }],
          },
        ],
      },
      {
        name: "Rigid Signs and Magnets",
        path: "/rigid-signs-and-magnets",
        description: "",
        products: [
          {
            name: "Magnets",
            path: "/magnets",
            description:
              "Flexible 30 mil. Magnet. High strength, indoor/outdoor use",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            corners: [
              { name: `1/2" Round Corner`, price: 1.5 },
              { name: `Straight Corner`, price: 1.5 },
            ],
            material: [{ name: "30mil. Matte Magnet", price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "Coroplast",
            path: "/coroplast",
            description:
              "4mm coroplast with H-Stake option. Custom size, indoor/outdoor use",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            productOptions: [
              { name: `Sign Only`, price: 1.5 },
              { name: `Sign + H Stake`, price: 1.5 },
            ],
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            grommet: [
              { name: `None`, price: 1.5 },
              { name: `Grommets (All four corners)`, price: 1.5 },
              { name: `Grommets (Top two corners)`, price: 1.5 },
            ],
            material: [{ name: "4mm White Coroplast", price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "Aluminum Sandwich Board",
            path: "/aluminum-sandwich-board",
            description: `1/8" aluminum composite. Also know as DiBond and ACP`,
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            holesPunch: [
              { name: `None`, price: 1.5 },
              { name: `All Four Corners`, price: 1.5 },
              { name: `Center-Top and Bottom`, price: 1.5 },
              { name: `Top Corners`, price: 1.5 },
            ],
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            corners: [
              { name: `Round Corner`, price: 1.5 },
              { name: `Straight Corner`, price: 1.5 },
            ],
            material: [{ name: `1/8" Aluminum Sandwich Board`, price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "PVC Board",
            path: "/pvc-board",
            description: "Durable for indoor and short term outdoor use",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            grommet: [
              { name: `None`, price: 1.5 },
              { name: `Grommets (All four corners)`, price: 1.5 },
              { name: `Grommets (Top two corners)`, price: 1.5 },
            ],
            material: [{ name: `1/8" white PVC`, price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "Styrene",
            path: "/styrene",
            description: "Flexible plastic board for indoor and outdoor use",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            grommet: [
              { name: `None`, price: 1.5 },
              { name: `Grommets (All four corners)`, price: 1.5 },
              { name: `Grommets (Top two corners)`, price: 1.5 },
            ],
            material: [{ name: `.020" White Polystyrene Film`, price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "Foam Board",
            path: "/foam-board",
            description: "Lightweight poster board, for indoor use only",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            material: [{ name: `3/16" White Foamcore`, price: 1.5 }],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "GatorFoam",
            path: "/gator-foam",
            description:
              "Extra heavy-duty surface. Lightweight, indoor use only",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            material: [
              { name: `3/16" White GatorFoam`, price: 1.5 },
              { name: `1/2" White GatorFoam`, price: 1.5 },
              { name: `3/16" Black GatorFoam`, price: 1.5 },
              { name: `1/2" Black GatorFoam`, price: 1.5 },
            ],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
          {
            name: "Aluminum Sign",
            path: "/aluminum-sign",
            description:
              ".040 Solid Aluminum for long-lasting indoor/outdoor signs",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            sizes: [
              { name: `12" x 18"`, price: 12.0 },
              { name: `18" x 12"`, price: 12.0 },
              { name: `18" x 24"`, price: 18.0 },
              { name: `24" x 18"`, price: 18.0 },
              { name: `24" x 36"`, price: 36.0 },
              { name: `36" x 24"`, price: 36.0 },
            ],
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            corners: [
              { name: `Round Corner`, price: 1.5 },
              { name: `Straight Corner`, price: 1.5 },
            ],
            holesPunch: [
              { name: `None`, price: 1.5 },
              { name: `All Four Corners`, price: 1.5 },
              { name: `Center-Top and Bottom`, price: 1.5 },
              { name: `Top Corners`, price: 1.5 },
            ],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
          },
        ],
      },
      {
        name: "Reflective Products",
        path: "/reflective-products",
        description: "",
        products: [
          {
            name: "Reflective Adhesive Vinyl",
            description: "",
            path: "/reflective-adhesive-vinyl",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            options: [{ name: "Starting at", price: 4.5 }],
          },
          {
            name: "Reflective Signicade A-Frame",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/reflective-signicade-aframe",
            graphic: [
              { name: "Mounted Reflective Vinyl - 1 pc", price: 1.5 },
              { name: "Mounted Reflective Vinyl - 2 pcs", price: 1.5 },
            ],
            material: [
              { name: "4 mil. Engineer Grade Reflective Vinyl", price: 1.5 },
            ],
            frameColor: [{ name: "White", price: 1.5 }],
          },
          {
            name: "Reflective Car Magnet",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/reflective-car-magnet",
            corners: [
              { name: `1/2" Round Corner`, price: 1.5 },
              { name: "Straight Corner", price: 1.5 },
            ],
            material: [{ name: "30 mil. Magnet", price: 1.5 }],
            print: [{ name: "Engineer Grade Reflective Vinyl", price: 1.5 }],
          },
          {
            name: "Reflective Coroplast",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/reflective-coroplast",
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            grommet: [
              { name: `None`, price: 1.5 },
              { name: `Grommets (All four corners)`, price: 1.5 },
              { name: `Grommets (Top two corners)`, price: 1.5 },
            ],

            material: [{ name: "4mm White Coroplast", price: 1.5 }],
            print: [
              {
                name: "Engineer Grade Reflective Vinyl",
                price: 1.5,
              },
            ],
          },
          {
            name: "Reflective Aluminum Sandwich Board",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/reflective-aluminum-sandwich-board",
            holesPunch: [
              { name: `None`, price: 1.5 },
              { name: `All Four Corners`, price: 1.5 },
              { name: `Center-Top and Bottom`, price: 1.5 },
              { name: `Top Corners`, price: 1.5 },
            ],
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            corners: [
              { name: `Round Corner`, price: 1.5 },
              { name: `Straight Corner`, price: 1.5 },
            ],
            material: [{ name: `1/8" Aluminum Sandwich Board`, price: 1.5 }],
            print: [{ name: "Engineer Grade Reflective Vinyl", price: 1.5 }],
          },
          {
            name: "Reflective Aluminum Sign",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/reflective-aluminum-sign",
            sizes: [
              { name: `12" x 18"`, price: 12.0 },
              { name: `18" x 12"`, price: 12.0 },
              { name: `18" x 24"`, price: 18.0 },
              { name: `24" x 18"`, price: 18.0 },
              { name: `24" x 36"`, price: 36.0 },
              { name: `36" x 24"`, price: 36.0 },
            ],
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            corners: [
              { name: `Round Corner`, price: 1.5 },
              { name: `Straight Corner`, price: 1.5 },
            ],
            holesPunch: [
              { name: `None`, price: 1.5 },
              { name: `All Four Corners`, price: 1.5 },
              { name: `Center-Top and Bottom`, price: 1.5 },
              { name: `Top Corners`, price: 1.5 },
            ],
            print: [{ name: "UV Ink - Matte Finish", price: 1.5 }],
            material: [{ name: ".040 Aluminum Sheet", price: 1.5 }],
          },
          {
            name: "Reflective PVC Board",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/reflective-pvc-board",
            numOfSides: [
              { name: `1 Side`, price: 1.5 },
              { name: `2 Sides`, price: 1.5 },
            ],
            grommet: [
              { name: `None`, price: 1.5 },
              { name: `Grommets (All four corners)`, price: 1.5 },
              { name: `Grommets (Top two corners)`, price: 1.5 },
            ],
            material: [{ name: `1/8" white PVC`, price: 1.5 }],
            lamination: [{ name: "UV Printed", price: 1.5 }],
          },
        ],
      },
      {
        name: "Dry Erase Products",
        path: "/dry-erase-products",
        description: "",
        products: [
          {
            name: "Dry Erase Adhesive Vinyl",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/dry-erase-adhesive-vinyl",
            material: [{ name: "4mil. Permanent Adhesive", price: 3.75 }],
            lamination: [{ name: "Dry Erase Coating", price: 3.75 }],
          },
          {
            name: "Dry Erase Signicade A-Frame",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/dry-erase-signicade-aframe",
            graphic: [
              { name: "Mounted Dry Erase Vinyl - 1 pc", price: 109.99 },
              { name: "Mounted Dry Erase Vinyl - 2 pcs", price: 109.99 },
            ],
            lamination: [{ name: "Dry Erase Coating", price: 3.75 }],
            frameColor: [{ name: "White", price: 3.75 }],
          },
          {
            name: "Dry Erase Magnet",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/dry-erase-magnet",
            corners: [
              { name: "Round Corner", price: 109.99 },
              { name: "Straight Corner", price: 109.99 },
            ],
            lamination: [{ name: "Dry Erase Coating", price: 3.75 }],
            material: [{ name: "30mil. Magnet", price: 3.75 }],
          },
          {
            name: "Dry Erase Coroplast",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/dry-erase-coroplast",
            numOfSides: [
              { name: "1 Side", price: 109.99 },
              { name: "2 Sides", price: 109.99 },
            ],
            grommet: [
              { name: "None", price: 109.99 },
              { name: "Grommets (All four corners)", price: 109.99 },
              { name: "Grommets (Top two corners)", price: 109.99 },
            ],
            lamination: [{ name: "Dry Erase Coating", price: 3.75 }],
            material: [{ name: "4mm White Coroplast", price: 3.75 }],
          },
          {
            name: "Dry Erase Aluminum Sandwich Board",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/dry-erase-aluminum-sandwich-board",
            numOfSides: [
              { name: "1 Side", price: 109.99 },
              { name: "2 Sides", price: 109.99 },
            ],
            holesPunch: [
              { name: "None", price: 109.99 },
              { name: "All Four Corners", price: 109.99 },
              { name: "Center-Top and Bottom", price: 109.99 },
              { name: "Top Corners", price: 109.99 },
            ],
            corners: [
              { name: "Round Corner", price: 109.99 },
              { name: "Straight Corner", price: 109.99 },
            ],
            lamination: [{ name: "Dry Erase Coating", price: 3.75 }],
            material: [{ name: `1/8" Aluminum Sandwich Board`, price: 3.75 }],
          },
          {
            name: "Dry Erase Foamcore",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/dry-erase-foamcore",
            numOfSides: [
              { name: "1 Side", price: 109.99 },
              { name: "2 Sides", price: 109.99 },
            ],
            lamination: [{ name: "Dry Erase Coating", price: 3.75 }],
            material: [{ name: `3/16" White Foamcore`, price: 3.75 }],
          },
          {
            name: "Dry Erase PVC Board",
            description: "",
            images: [
              standardChannelLetter,
              standardChannelLetter2,
              standardCLExample,
            ],
            path: "/dry-erase-pvc-board",
            numOfSides: [
              { name: "1 Side", price: 109.99 },
              { name: "2 Sides", price: 109.99 },
            ],
            grommet: [
              { name: "None", price: 109.99 },
              { name: "Grommets (All four corners)", price: 109.99 },
              { name: "Grommets (Top two corners)", price: 109.99 },
            ],
            lamination: [{ name: "Dry Erase Coating", price: 3.75 }],
            material: [
              {
                name: `1/8" white PVC`,
                price: 3.75,
              },
            ],
          },
        ],
      },
    ],
    products: [
      {
        name: "DTF",
        path: "/dtf",
        images: [
          standardChannelLetter,
          standardChannelLetter2,
          standardCLExample,
        ],
      },
      // {
      //   name: "Backlit Film",
      //   path: "/backlit-film",
      //   images: [
      //     standardChannelLetter,
      //     standardChannelLetter2,
      //     standardCLExample,
      //   ],
      // },
      // {
      //   name: "Premium Window Cling",
      //   path: "/window-cling",
      //   images: [
      //     standardChannelLetter,
      //     standardChannelLetter2,
      //     standardCLExample,
      //   ],
      // },
      // {
      //   name: "Posters",
      //   path: "/posters",
      //   images: [
      //     standardChannelLetter,
      //     standardChannelLetter2,
      //     standardCLExample,
      //   ],
      // },
      // {
      //   name: "Styrene",
      //   path: "/styrene",
      //   images: [
      //     standardChannelLetter,
      //     standardChannelLetter2,
      //     standardCLExample,
      //   ],
      // },
      // {
      //   name: "Popup",
      //   path: "/popup",
      //   images: [
      //     standardChannelLetter,
      //     standardChannelLetter2,
      //     standardCLExample,
      //   ],
      // },
      // {
      //   name: "Canvas Roll",
      //   path: "/canvas-roll",
      //   images: [
      //     standardChannelLetter,
      //     standardChannelLetter2,
      //     standardCLExample,
      //   ],
      // },
      // {
      //   name: "Material",
      //   path: "/material",
      //   images: [
      //     standardChannelLetter,
      //     standardChannelLetter2,
      //     standardCLExample,
      //   ],
      // },
    ],
  },
];
