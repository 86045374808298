import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { removeFromCart } from "../../store/cartSlice";
import CartItem from "../../components/Cart/CartItem";
import StripeContainer from "../../components/Stripe/CheckoutButton";
import { Link } from "react-router-dom";
import { EmptyCartIcon } from "../../assets/icons/icons";

const Cart: React.FC = () => {
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const dispatch = useDispatch();

  const totalPrice = cartItems.reduce((total, item) => total + item.total, 0);

  const allArtworkUploaded = cartItems.every(
    (item) => item.productArtwork && item.productArtwork.trim() !== ""
  );

  const handleRemoveFromCart = (index: number) => {
    dispatch(removeFromCart(index));
  };

  return (
    <div className="w-full max-w-2xl mx-auto py-12">
      <div className="grid gap-8">
        <div className="grid gap-4">
          <h1 className="text-2xl font-bold tracking-tight">Your Cart</h1>
          {!allArtworkUploaded && (
            <div className="mt-2 bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
              <p>Please upload all product artwork to checkout.</p>
            </div>
          )}
          <div className="grid gap-6">
            {cartItems.map((item, index) => (
              <CartItem
                key={index}
                item={item}
                index={index}
                onRemove={() => handleRemoveFromCart(index)}
              />
            ))}
          </div>
        </div>
        {cartItems.length > 0 ? (
          <div className="grid gap-4 border-t pt-4">
            <div className="flex items-center justify-between">
              <span className="font-semibold">Total</span>
              <span className="text-2xl font-bold">
                ${totalPrice.toFixed(2)}
              </span>
            </div>
            <StripeContainer cartItems={cartItems} />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center space-y-6">
            <EmptyCartIcon className="w-24 h-24 text-gray-400" />

            <h1 className="text-3xl font-bold text-gray-800">
              Your Cart is Empty
            </h1>
            <p className="text-gray-600 text-center">
              It looks like you haven't added any items to your cart yet.
            </p>
            <Link
              to="/"
              className="bg-medium-gold hover:bg-dark-gold text-white font-bold py-2 px-4 rounded transition duration-200"
            >
              Continue Shopping
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
