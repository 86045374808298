import React, { useState } from "react";
import {
  HomeIcon,
  PackageIcon,
  MessageSquareIcon,
} from "../../assets/icons/icons"; // Ensure these are correctly imported
import { NavigationLink } from "./NavigationLink";
import { useAuth0 } from "@auth0/auth0-react";
import UserMenu from "./UserMenu";
import ProductSearch from "./ProductSearch";
import MobileMenu from "./mobile/MobileMenu";
import Loading from "../Loading";
import CartIcon from "../Cart/CartIcon";
import { useLocation } from "react-router-dom";

const TopNavigationBar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const links = [
    { Icon: HomeIcon, text: "Home", url: "/" },
    { Icon: HomeIcon, text: "Products", url: "/products" },
    {
      Icon: MessageSquareIcon,
      text: "Contact Us",
      url: "/contact-us",
      authRequired: true,
    },
    { Icon: PackageIcon, text: "Orders", url: "/orders", authRequired: true },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) {
    return <Loading message="Loading..." />;
  }

  return (
    <nav className="bg-deep-blue border-b border-gray-200 shadow-sm dark:bg-gray-800">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Company Name */}
          <div className="flex items-center">
            <span className="text-white text-xl font-bold dark:text-white cursor-pointer">
              H-Marketing
            </span>
          </div>

          {/* Search Bar */}
          <div className="flex-1 mx-auto hidden sm:block max-w-md">
            <ProductSearch />
          </div>

          {/* Navigation Links for Desktop */}
          <div className="hidden sm:flex sm:items-center sm:space-x-6">
            {links.map(
              (link) =>
                (!link.authRequired || isAuthenticated) && (
                  <NavigationLink
                    key={link.url}
                    Icon={link.Icon}
                    text={link.text}
                    url={link.url}
                    isActive={location.pathname === link.url}
                  />
                )
            )}
          </div>

          {/* User Menu and Cart */}
          {isAuthenticated ? (
            <>
              <UserMenu />
              <NavigationLink
                Icon={CartIcon}
                url="/cart"
                isActive={location.pathname === "/cart"}
              />
            </>
          ) : (
            <button
              onClick={() => loginWithRedirect()}
              className="px-4 py-2 rounded-md text-sm font-medium text-white bg-medium-gold hover:bg-dark-gold"
            >
              Sign In
            </button>
          )}

          {/* Mobile Menu Button */}
          <MobileMenu isOpen={isOpen} toggleMenu={toggleMenu} />
        </div>
      </div>
    </nav>
  );
};

export default TopNavigationBar;
