import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../../../constants/products/pricePerSquareFT";

const DryEraseFoamcore = () => {
  const productName = "Dry Erase Foamcore";

  // Fetch the product data by name
  const {
    data: dryEraseFoamcoreProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = dryEraseFoamcoreProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!dryEraseFoamcoreProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={dryEraseFoamcoreProduct.name}
      images={presignedUrls ?? []}
      numOfSides={dryEraseFoamcoreProduct?.numOfSides}
      lamination={dryEraseFoamcoreProduct?.lamination}
      material={dryEraseFoamcoreProduct?.material}
      path={dryEraseFoamcoreProduct?.path}
      customSizing={true}
      pricePerSqFt={pricePerSquareFootMap.DryEraseFoamcore}
    />
  );
};

export default DryEraseFoamcore;
