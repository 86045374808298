import React from "react";
import BannersProductPage from "../../components/Banners/BannersProductPage";
import useProductByName from "../../hooks/product/useProductByName";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../constants/products/pricePerSquareFT";

const TensionFabricProductPage = () => {
  const productName = "Tension Fabric";

  // Fetch the product data by name
  const {
    data: tensionFabricBannerProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = tensionFabricBannerProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!tensionFabricBannerProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <BannersProductPage
      name="Tension Fabric"
      images={presignedUrls ?? []}
      material={tensionFabricBannerProduct?.material}
      polePocket={tensionFabricBannerProduct?.polePocket}
      hem={tensionFabricBannerProduct?.hem}
      grommet={tensionFabricBannerProduct?.grommet}
      velcro={tensionFabricBannerProduct?.velcro}
      path={tensionFabricBannerProduct?.path}
      customSizing={true}
      pricePerSqFt={pricePerSquareFootMap.TensionFabric}
    />
  );
};

export default TensionFabricProductPage;
