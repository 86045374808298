import React from "react";
import { Order } from "../../interface/orders/order";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";

interface OrderCardProps {
  order: Order;
  onClick: () => void;
}

const OrderCard: React.FC<OrderCardProps> = ({ order, onClick }) => {
  const extractKeyFromUrl = (url: string) => {
    const urlObj = new URL(url);
    return decodeURIComponent(urlObj.pathname.substring(1));
  };

  const artworkKeys = order.items.map((item) =>
    extractKeyFromUrl(item.artwork || "")
  );

  const {
    data: artworkURLs,
    isError,
    isLoading,
  } = usePresignedUrls(artworkKeys, s3Buckets.OrderArtwork);

  return (
    <div
      className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold text-gray-800">
          Order ID: {order.orderId}
        </h2>
        <span
          className={`px-3 py-1 rounded-full text-sm font-medium ${
            order.status === "done"
              ? "bg-green-100 text-green-800"
              : order.status === "in progress"
              ? "bg-yellow-100 text-yellow-800"
              : order.status === "new"
              ? "bg-yellow-100 text-yellow-800"
              : order.status === "cancelled"
              ? "bg-red-100 text-red-800"
              : "bg-blue-100 text-blue-800"
          }`}
        >
          {order.status.toUpperCase()}
        </span>
      </div>
      <div className="flex items-center justify-between mb-4">
        <p className="text-gray-600">
          <strong>Total:</strong> ${order.totalAmount.toFixed(2)}
        </p>
        <p className="text-gray-600">
          <strong>Created At:</strong>{" "}
          {new Date(order.createdAt).toLocaleString()}
        </p>
      </div>
      <div className="flex flex-row flex-wrap gap-2 justify-center mb-4">
        {isLoading && <div>Loading artworks...</div>}
        {isError && <div>Error loading artworks</div>}
        {artworkURLs &&
          artworkURLs.map((url, index) => (
            <div key={index} className="relative">
              <img
                src={url}
                alt={`Artwork preview ${index + 1}`}
                className="h-24 w-24 object-cover rounded"
              />
            </div>
          ))}
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-semibold text-gray-700">Items:</h3>
        <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
          {order.items.map((item, index) => (
            <li key={index} className="text-gray-600">
              <span className="font-medium">{item.product}</span> - Quantity:{" "}
              {item.quantity}, Total: ${item.total.toFixed(2)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OrderCard;
