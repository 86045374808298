import React from "react";
import { channelLetterProducts } from "../../constants/channel-letter/channelLetters";
import ProductDisplay from "../../components/Products/ProductDisplay";

const ChannelLetters = () => {
  return (
    <ProductDisplay
      title="Channel Letters"
      description={channelLetterProducts.description}
      products={channelLetterProducts.items}
    />
  );
};

export default ChannelLetters;
