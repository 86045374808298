import React, { useEffect, useState } from "react";
import { Trash2Icon, PlusIcon, CheckIcon } from "../../assets/icons/icons";
import { useDispatch } from "react-redux";
import { updateProductArtwork } from "../../store/cartSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import s3 from "../../utils/s3Config";

interface CartItemProps {
  item: {
    product: string;
    productImg?: string;
    options: Record<string, any>;
    total: number;
    productArtwork?: any;
  };
  index: number;
  onRemove: () => void;
}

const CartItem: React.FC<CartItemProps> = ({ item, index, onRemove }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploaded, setIsUploaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if the product has artwork uploaded
    if (item.productArtwork && item.productArtwork !== "") {
      setIsUploaded(true);
    }
  }, [item.productArtwork]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] || null;
    const fileId = uuidv4();
    if (file) {
      const encodedFileName = encodeURIComponent(file.name);
      const params = {
        Bucket: "hmktg-shop-orders-artwork",
        Key: `${fileId}/${encodedFileName}`,
        Body: file,
        ContentType: file.type,
      };

      setIsUploading(true);
      setProgress(0);

      s3.upload(params)
        .on("httpUploadProgress", (event) => {
          const progress = Math.round((event.loaded / event.total) * 100);
          setProgress(progress);
        })
        .promise()
        .then((data: any) => {
          dispatch(updateProductArtwork({ index, artwork: data.Location }));
          setIsUploading(false);
          setIsUploaded(true);
        })
        .catch((error: string) => {
          setIsUploading(false);
          console.error("Error uploading file:", error);
          toast.error("Error uploading artwork");
        });
    }
  };

  const renderOptions = (options: Record<string, any>) => {
    return (
      <ul className="list-disc list-inside space-y-1">
        {Object.entries(options)
          .filter(([_, value]) => {
            if (typeof value === "object" && value !== null) {
              // Check if object (like Dimensions) has any non-empty values
              return Object.values(value).some((v) => v);
            }
            return value !== "";
          }) // Filter out empty values and empty objects
          .map(([key, value]) => (
            <li key={key} className="text-gray-600">
              <span className="font-semibold">{key}:</span>{" "}
              {typeof value === "object" ? renderDimensions(value) : value}
            </li>
          ))}
      </ul>
    );
  };

  const renderDimensions = (dimensions: {
    heightFt: number;
    heightIn: number;
    widthFt: number;
    widthIn: number;
  }) => {
    const { heightFt, heightIn, widthFt, widthIn } = dimensions;
    if (!heightFt && !heightIn && !widthFt && !widthIn) {
      return null; // Return null if all dimensions are empty
    }
    return (
      <span>
        {heightFt}ft {heightIn}in x {widthFt}ft {widthIn}in
      </span>
    );
  };

  return (
    <div className="flex items-start gap-4 bg-white p-4 rounded-lg shadow-md transition-transform transform hover:scale-105">
      <img
        src={item.productImg}
        alt="productImg"
        className="rounded-lg object-cover h-24 w-24"
      />
      <div className="flex flex-col gap-1 flex-grow">
        <h3 className="font-semibold text-lg">{item.product}</h3>
        <div className="text-sm text-gray-600">
          <span className="font-semibold">Total:</span> ${item.total.toFixed(2)}
        </div>
        <div className="text-sm text-gray-600">
          <span className="font-semibold">Quantity:</span>{" "}
          {item.options.Quantity}
        </div>
        <button
          className="text-sm text-deep-blue hover:underline mt-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? "Hide Details" : "View Details"}
        </button>
        <div
          className={`overflow-hidden transition-all duration-300 ${
            isOpen ? "max-h-96" : "max-h-0"
          }`}
        >
          {isOpen && (
            <div className="mt-2 text-sm leading-tight">
              <h4 className="text-gray-700 font-semibold">Options:</h4>
              {renderOptions(item.options)}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-end justify-center gap-2">
        <label
          htmlFor={`artwork-upload-${index}`}
          className={`${
            isUploaded ? "bg-green-500" : "bg-medium-gold"
          } hover:bg-dark-gold text-white font-bold py-2 px-4 rounded transition-colors duration-200 cursor-pointer flex items-center gap-2`}
        >
          {isUploaded ? (
            <>
              <CheckIcon className="h-4 w-4" />
              Artwork Uploaded
            </>
          ) : (
            <>
              <PlusIcon className="h-4 w-4" />
              Add Artwork
            </>
          )}
        </label>
        <input
          id={`artwork-upload-${index}`}
          type="file"
          className="hidden"
          onChange={handleFileChange}
        />
        {isUploading && (
          <div className="w-full bg-gray-200 rounded-full mt-2">
            <div
              className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: `${progress}%` }}
            >
              {progress}%
            </div>
          </div>
        )}
        <button
          onClick={onRemove}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center gap-2"
        >
          <Trash2Icon className="h-4 w-4" />
          <span className="sr-only">Remove</span>
        </button>
      </div>
    </div>
  );
};

export default CartItem;
