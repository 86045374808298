import React, { useState } from "react";
import {
  FaqProps,
  ProductDetailProps,
  TabContentProps,
} from "../../interface/product/ProductDetails";

const ProductDetails: React.FC<ProductDetailProps> = ({ details }) => {
  const [activeTab, setActiveTab] = useState<
    "description" | "breakdown" | "warranty" | "faq"
  >("description");

  const TabContent: React.FC<TabContentProps> = ({ content }) => (
    <p className="text-gray-600">{content}</p>
  );

  const FAQContent: React.FC<FaqProps> = ({ faq }) => (
    <div>
      {faq.map((item, index) => (
        <div key={index} className="mt-2">
          <h4 className="font-semibold">{item.question}</h4>
          <p>{item.answer}</p>
        </div>
      ))}
    </div>
  );

  const getContent = () => {
    switch (activeTab) {
      case "description":
        return <TabContent content={details.description} />;
      case "breakdown":
        return <TabContent content={details.breakdown} />;
      case "warranty":
        return <TabContent content={details.warranty} />;
      case "faq":
        return <FAQContent faq={details.faq} />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mt-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Product Details
      </h2>
      <ul className="flex border-b">
        {["description", "breakdown", "warranty", "faq"].map((key) => (
          <li
            key={key}
            className={`mr-6 ${
              activeTab === key ? "border-b-2 border-blue-500" : ""
            }`}
          >
            <button
              className="text-gray-800 font-semibold py-2 px-4 focus:outline-none"
              onClick={() => setActiveTab(key as any)}
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </button>
          </li>
        ))}
      </ul>
      <div className="mt-4">{getContent()}</div>
    </div>
  );
};

export default ProductDetails;
