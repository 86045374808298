import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { XIcon } from "../../assets/icons/icons";
import { useUser } from "../../context/UserContext";

const UserMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { logout } = useAuth0();
  const user = useUser();

  return (
    <div className="relative">
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="flex items-center text-gray-800 dark:text-white"
      >
        <img
          src={user.user?.picture}
          alt={user?.user?.name}
          className="h-8 w-8 rounded-full border border-gray-300"
        />
      </button>
      {isMenuOpen && (
        <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg py-1 z-50">
          <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
            <span className="text-gray-800 font-semibold">
              {user.user?.name}
            </span>
            <button
              onClick={() => setIsMenuOpen(false)}
              className="text-gray-800 hover:text-gray-500"
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="py-1">
            <a
              href="/"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Notifications
            </a>
            {/* <a
              href="/profile"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Profile
            </a> */}
            <a
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                })
              }
              href="/"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Logout
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
