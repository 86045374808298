import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";

const ReflectiveCoroplast = () => {
  const productName = "Reflective Coroplast";

  // Fetch the product data by name
  const {
    data: reflectiveCoroplastProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = reflectiveCoroplastProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!reflectiveCoroplastProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={reflectiveCoroplastProduct.name}
      images={presignedUrls ?? []}
      numOfSides={reflectiveCoroplastProduct?.numOfSides}
      grommet={reflectiveCoroplastProduct?.grommet}
      material={reflectiveCoroplastProduct?.material}
      print={reflectiveCoroplastProduct?.print}
      path={reflectiveCoroplastProduct?.path}
      customSizing={true}
    />
  );
};

export default ReflectiveCoroplast;
