import React, { useCallback, useEffect, useState } from "react";
import { ChannelLetterProductProps } from "../../../interface/channel-letter/ChannelLetterProductProps";
import ProductDetails from "../ProductDetails";
import ImageGallery from "../ImageGallery";
import { pricePerSquareFootMap } from "../../../constants/products/pricePerSquareFT";
import BackButton from "../BackButton";
import CustomSizeInput from "../CustomSizeInput";
import OrderDetails from "../OrderDetails";
import AddToCartButton from "../AddToCartButton";

const ChannelLetterProductPage: React.FC<ChannelLetterProductProps> = ({
  name,
  images,
  options,
}) => {
  const [jobName, setJobName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [storePickup, setStorePickup] = useState(false);
  const [characters, setCharacters] = useState("");
  const [total, setTotal] = useState(0);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [squareFeet, setSquareFeet] = useState(0);
  const [customDimensions, setCustomDimensions] = useState({
    heightFt: 0,
    heightIn: 0,
    widthFt: 0,
    widthIn: 0,
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [errors, setErrors] = useState({
    jobName: "",
    quantity: "",
    characters: "",
  });

  // const stateRefs = {
  //   jobName,
  //   quantity: quantity.toString(), // Convert numeric state to string if validation expects string
  //   characters,
  // };

  useEffect(() => {
    calculateTotalPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squareFeet, characters, quantity]);

  const validateField = (field: keyof typeof errors, value: any) => {
    let errorMsg = "";
    switch (field) {
      case "jobName":
        errorMsg = !value ? "Job name is required" : "";
        break;
      case "quantity":
        errorMsg =
          Number(value) <= 0 || isNaN(Number(value))
            ? "Quantity must be greater than zero"
            : "";
        break;
      case "characters":
        errorMsg = !value ? "Characters field is required" : "";
        break;
      default:
        errorMsg = "Invalid field";
    }
    setErrors((prev) => ({ ...prev, [field]: errorMsg }));
    return errorMsg === "";
  };

  const onSizeChange = useCallback(
    (
      sqft: number,
      cost: number,
      dimensions: {
        heightFt: number;
        heightIn: number;
        widthFt: number;
        widthIn: number;
      }
    ) => {
      setSquareFeet(sqft);
      setCustomDimensions(dimensions);
    },
    []
  );

  const handleCharactersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCharacters(value);
    validateField("characters", value);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const calculateTotalPrice = () => {
    let calculatedPrice = squareFeet * pricePerSquareFootMap.ChannelLetter;

    let additionalPrice = 0;

    // If characters are used, add an additional price based on number of characters
    if (characters) {
      const numberOfLetters = characters.length;
      additionalPrice = numberOfLetters * calculatedPrice;
    }

    // Convert the final price to a number with two decimal places before setting the total
    setTotal(Number((additionalPrice * quantity).toFixed(2)));
  };

  const productToAdd = {
    product: name,
    productImg: selectedImage,
    options: {
      "Job Name": jobName,
      Quantity: quantity,
      "Store Pickup": storePickup === true ? "Yes" : "",
      Dimensions: customDimensions,
    },
    total,
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 bg-cool-gray-50 shadow-xl rounded-lg">
      <div className="flex items-center mb-6">
        <BackButton />
        <h1 className="text-3xl font-semibold text-gray-800 ml-4">{name}</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0">
          <ImageGallery
            images={images}
            selectedImage={selectedImage}
            onSelectImage={setSelectedImage}
          />
        </div>
        <div className="md:w-1/2 space-y-4">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <CustomSizeInput
              onSizeChange={onSizeChange}
              pricePerSqFt={pricePerSquareFootMap.ChannelLetter}
            />
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            {options && options.length > 0 && (
              <div className="mb-4">
                <h2 className="text-xl font-semibold mb-3">Options</h2>
                <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none"
                >
                  {options.map((option, index) => (
                    <option key={index} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="mb-4">
              <h2 className="text-xl font-semibold mb-3">Characters</h2>
              <input
                type="text"
                value={characters}
                onChange={handleCharactersChange}
                name="characters"
                className={`block w-full px-4 py-2 border ${
                  errors.characters ? "border-red-500" : "border-gray-300"
                } rounded-lg shadow-sm focus:outline-none`}
              />
              {errors.characters && (
                <p className="text-red-500 text-xs mt-1">{errors.characters}</p>
              )}
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md mt-4">
            <OrderDetails
              jobName={jobName}
              setJobName={setJobName}
              quantity={quantity}
              setQuantity={setQuantity}
              storePickup={storePickup}
              setStorePickup={setStorePickup}
            />
            <AddToCartButton product={productToAdd} />
          </div>
        </div>
      </div>
      <ProductDetails
        details={{
          description: "This is a detailed description of the product...",
          breakdown: "Here is a breakdown of the components...",
          warranty: "Warranty information will be detailed here...",
          faq: [
            {
              question: "How long is the product warranty?",
              answer: "The product comes with a 2-year warranty.",
            },
            {
              question: "Can I install this product myself?",
              answer: "Yes, it is designed for easy installation.",
            },
          ],
        }}
      />
    </div>
  );
};

export default ChannelLetterProductPage;
