import React, { useCallback, useEffect, useState } from "react";
import { ProductProps } from "../../../interface/product/Product";
import BackButton from "../BackButton";
import ImageGallery from "../ImageGallery";
import OptionSection from "../OptionSection";
import CustomSizeInput from "../CustomSizeInput";
import { pricePerSquareFootMap } from "../../../constants/products/pricePerSquareFT";
import OrderDetails from "../OrderDetails";
import AddToCartButton from "../AddToCartButton";
import ProductDetails from "../ProductDetails";

const RealEstateProductPage: React.FC<ProductProps> = ({
  name,
  images,
  productOptions,
  sizes,
  graphic,
  insert,
  material,
  path,
  LEDlight,
  hem,
  grommet,
  customSizing,
  windslit,
  webbing,
  corners,
  rope,
  colorProfile,
  velcro,
  rider,
  pennantFlag,
  holesPunch,
}) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [selectedProductOption, setselectedProductOption] = useState(
    productOptions?.[0] || { name: "", price: 0 }
  );
  const [selectedSize, setSelectedSize] = useState(
    sizes?.[0] || { name: "", price: 0 }
  );
  const [selectedGraphic, setSelectedGraphic] = useState(
    graphic?.[0] || { name: "", price: 0 }
  );
  const [selectedInsert, setselectedInsert] = useState(
    insert?.[0] || { name: "", price: 0 }
  );
  const [selectedMaterial, setselectedMaterial] = useState(
    material?.[0] || { name: "", price: 0 }
  );
  const [selectedLEDlight, setselectedLEDlight] = useState(
    LEDlight?.[0] || { name: "", price: 0 }
  );
  const [selectedHem, setselectedHem] = useState(
    hem?.[0] || { name: "", price: 0 }
  );
  const [selectedGrommet, setselectedGrommet] = useState(
    grommet?.[0] || { name: "", price: 0 }
  );
  const [selectedWindslit, setselectedWindslit] = useState(
    windslit?.[0] || { name: "", price: 0 }
  );
  const [selectedWebbing, setselectedWebbing] = useState(
    webbing?.[0] || { name: "", price: 0 }
  );
  const [selectedCorners, setselectedCorners] = useState(
    corners?.[0] || { name: "", price: 0 }
  );
  const [selectedRope, setselectedRope] = useState(
    rope?.[0] || { name: "", price: 0 }
  );
  const [selectedColorProfile, setselectedColorProfile] = useState(
    rope?.[0] || { name: "", price: 0 }
  );
  const [selectedVelcro, setselectedVelcro] = useState(
    velcro?.[0] || { name: "", price: 0 }
  );
  const [selectedRider, setselectedRider] = useState(
    rider?.[0] || { name: "", price: 0 }
  );
  const [selectedPennantFlag, setselectedPennantFlag] = useState(
    pennantFlag?.[0] || { name: "", price: 0 }
  );
  const [selectedHolesPunch, setselectedHolesPunch] = useState(
    pennantFlag?.[0] || { name: "", price: 0 }
  );
  const [jobName, setJobName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [storePickup, setStorePickup] = useState(false);
  const [total, setTotal] = useState(0);
  const [additionalSquareFootCost, setAdditionalSquareFootCost] = useState(0);
  const [customDimensions, setCustomDimensions] = useState({
    heightFt: 0,
    heightIn: 0,
    widthFt: 0,
    widthIn: 0,
  });

  const onSizeChange = useCallback(
    (
      sqft: number,
      cost: number,
      dimensions: {
        heightFt: number;
        heightIn: number;
        widthFt: number;
        widthIn: number;
      }
    ) => {
      setAdditionalSquareFootCost(cost);
      setCustomDimensions(dimensions);
    },
    []
  );

  const calculateTotalPrice = useCallback(() => {
    const baseTotal =
      (selectedProductOption.price ?? 0) +
      (selectedSize.price ?? 0) +
      (selectedGraphic.price ?? 0) +
      (selectedMaterial.price ?? 0) +
      (selectedProductOption.name === "Stand + Insert"
        ? selectedLEDlight.price ?? 0
        : 0) +
      (selectedHem.price ?? 0) +
      (selectedInsert.price ?? 0) +
      (selectedGrommet.price ?? 0) +
      (selectedWindslit.price ?? 0) +
      (selectedWebbing.price ?? 0) +
      (selectedCorners.price ?? 0) +
      (selectedRope.price ?? 0) +
      (selectedColorProfile.price ?? 0) +
      (selectedVelcro.price ?? 0) +
      (selectedRider.price ?? 0) +
      (selectedProductOption.name === "Sign + Frame"
        ? selectedPennantFlag.price ?? 0
        : 0) +
      (selectedHolesPunch.price ?? 0) +
      additionalSquareFootCost;
    setTotal(baseTotal * quantity);
  }, [
    selectedProductOption,
    selectedSize,
    selectedGraphic,
    selectedMaterial,
    selectedLEDlight,
    selectedHem,
    selectedInsert,
    selectedGrommet,
    selectedWindslit,
    selectedWebbing,
    selectedCorners,
    selectedRope,
    selectedColorProfile,
    selectedVelcro,
    selectedRider,
    selectedPennantFlag,
    selectedHolesPunch,
    quantity,
    additionalSquareFootCost,
  ]);

  useEffect(() => {
    calculateTotalPrice();
  }, [calculateTotalPrice]);

  const productToAdd = {
    product: name,
    productImg: selectedImage,
    options: {
      "Product Option": selectedProductOption.name,
      Size: selectedSize.name,
      Graphic: selectedGraphic.name,
      Insert: selectedInsert.name,
      Material: selectedMaterial.name,
      "LED Light": selectedLEDlight.name,
      Hem: selectedHem.name,
      Grommet: selectedGrommet.name,
      Windslit: selectedWindslit.name,
      Webbing: selectedWebbing.name,
      Corners: selectedCorners.name,
      Rope: selectedRope.name,
      "Color Profile": selectedColorProfile.name,
      Velcro: selectedVelcro.name,
      Rider: selectedRider.name,
      "Pennant Flag": selectedPennantFlag.name,
      "Holes Punch": selectedHolesPunch.name,
      "Job Name": jobName,
      Quantity: quantity,
      "Store Pickup": storePickup === true ? "Yes" : "",
      Dimensions: customDimensions,
    },
    total,
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 bg-cool-gray-50 shadow-xl rounded-lg">
      <div className="flex items-center mb-6">
        <BackButton />
        <h1 className="text-3xl font-semibold text-gray-800">{name}</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0">
          <ImageGallery
            images={images}
            selectedImage={selectedImage}
            onSelectImage={setSelectedImage}
          />
        </div>
        <div className="md:w-1/2 space-y-4">
          {customSizing && (
            <div className="bg-white p-6 rounded-lg shadow-md">
              <CustomSizeInput
                onSizeChange={onSizeChange}
                pricePerSqFt={pricePerSquareFootMap.Banners}
              />
            </div>
          )}

          <div className="bg-white p-6 rounded-lg shadow-md">
            {productOptions && (
              <OptionSection
                title="Product Option"
                options={productOptions}
                selected={selectedProductOption}
                setSelected={setselectedProductOption}
                displayType="card"
              />
            )}
            {sizes && (
              <OptionSection
                title="Size"
                options={sizes}
                selected={selectedSize}
                setSelected={setSelectedSize}
                displayType="dropdown"
              />
            )}
            {graphic && (
              <OptionSection
                title="Graphic"
                options={graphic}
                selected={selectedGraphic}
                setSelected={setSelectedGraphic}
                displayType="dropdown"
              />
            )}
            {insert && (
              <OptionSection
                title="Insert"
                options={insert}
                selected={selectedInsert}
                setSelected={setselectedInsert}
                displayType="dropdown"
              />
            )}
            {material && (
              <OptionSection
                title="Material"
                options={material}
                selected={selectedMaterial}
                setSelected={setselectedMaterial}
                displayType="dropdown"
              />
            )}
            {selectedProductOption.name === "Stand + Insert" && LEDlight && (
              <OptionSection
                title="LED Light"
                options={LEDlight}
                selected={selectedLEDlight}
                setSelected={setselectedLEDlight}
                displayType="dropdown"
              />
            )}
            {hem && (
              <OptionSection
                title="Hem"
                options={hem}
                selected={selectedHem}
                setSelected={setselectedHem}
                displayType="dropdown"
              />
            )}
            {grommet && (
              <OptionSection
                title="Grommet"
                options={grommet}
                selected={selectedGrommet}
                setSelected={setselectedGrommet}
                displayType="dropdown"
              />
            )}
            {windslit && (
              <OptionSection
                title="Windslit"
                options={windslit}
                selected={selectedWindslit}
                setSelected={setselectedWindslit}
                displayType="dropdown"
              />
            )}

            {webbing && (
              <OptionSection
                title="Webbing"
                options={webbing}
                selected={selectedWebbing}
                setSelected={setselectedWebbing}
                displayType="dropdown"
              />
            )}

            {corners && (
              <OptionSection
                title="Corners"
                options={corners}
                selected={selectedCorners}
                setSelected={setselectedCorners}
                displayType="dropdown"
              />
            )}

            {rope && (
              <OptionSection
                title="Rope"
                options={rope}
                selected={selectedRope}
                setSelected={setselectedRope}
                displayType="dropdown"
              />
            )}

            {colorProfile && (
              <OptionSection
                title="Color Profile"
                options={colorProfile}
                selected={selectedColorProfile}
                setSelected={setselectedColorProfile}
                displayType="dropdown"
              />
            )}

            {velcro && (
              <OptionSection
                title="Velcro"
                options={velcro}
                selected={selectedVelcro}
                setSelected={setselectedVelcro}
                displayType="dropdown"
              />
            )}

            {rider && (
              <OptionSection
                title="Rider"
                options={rider}
                selected={selectedRider}
                setSelected={setselectedRider}
                displayType="dropdown"
              />
            )}

            {selectedProductOption.name === "Sign + Frame" && pennantFlag && (
              <OptionSection
                title="Pennant Flag"
                options={pennantFlag}
                selected={selectedPennantFlag}
                setSelected={setselectedPennantFlag}
                displayType="dropdown"
              />
            )}

            {holesPunch && (
              <OptionSection
                title="Holes Punch"
                options={holesPunch}
                selected={selectedHolesPunch}
                setSelected={setselectedHolesPunch}
                displayType="dropdown"
              />
            )}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md mt-4">
            <OrderDetails
              jobName={jobName}
              setJobName={setJobName}
              quantity={quantity}
              setQuantity={setQuantity}
              storePickup={storePickup}
              setStorePickup={setStorePickup}
            />
            <AddToCartButton product={productToAdd} />
          </div>
        </div>
      </div>
      <ProductDetails
        details={{
          description: "This is a detailed description of the product...",
          breakdown: "Here is a breakdown of the components...",
          warranty: "Warranty information will be detailed here...",
          faq: [
            {
              question: "How long is the product warranty?",
              answer: "The product comes with a 2-year warranty.",
            },
            {
              question: "Can I install this product myself?",
              answer: "Yes, it is designed for easy installation.",
            },
          ],
        }}
      />
    </div>
  );
};

export default RealEstateProductPage;
