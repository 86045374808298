import React from "react";
import BannerStandsProductPage from "../../../components/Products/Displays/BannerStandsProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const StepRepeatBackdropProductPage = () => {
  const productName = "Step and Repeat Backdrop";

  // Fetch the product data by name
  const {
    data: stepRepeatProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = stepRepeatProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!stepRepeatProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <BannerStandsProductPage
      name={stepRepeatProduct.name}
      productOptions={stepRepeatProduct?.productOptions}
      images={presignedUrls ?? []}
      sizes={stepRepeatProduct?.sizes}
      graphic={stepRepeatProduct?.graphic}
      path={stepRepeatProduct?.path}
      customSizing={false}
    />
  );
};

export default StepRepeatBackdropProductPage;
