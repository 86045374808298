export const pricePerSquareFootMap = {
  ChannelLetter: 44.99,
  ThirteenozVinylBanner: 2.5,
  EighteenozVinylBanner: 3.0,
  BacklitBanner: 6.5,
  MeshBanner: 2.5,
  IndoorBanner: 3.0,
  FabricBanner: 3.8,
  PoleBanner: 3,
  TensionFabric: 6.0,
  AdvertisingFlag: 3.0,
  AdhesiveVinvyl: 2.5,
  HighPerformanceVinyl: 6.5,
  Controltac: 9.8,
  AdhesiveWindowPerf: 6.0,
  PremiumWindowCling: 6.0,
  AdhesiveWallFabric: 7.0,
  AdhesiveClearVinyl: 7.0,
  AdhesiveTranslucentVinyl: 8.0,
  FloorGraphics: 9.5,
  FrostedVinyl: 8.0,
  ReflectiveAdhesiveVinyl: 8.0,
  DryEraseAdhesiveVinyl: 7.0,
  Magnets: 8.95,
  Coroplast: 5.5,
  DryEraseSignicadeAFrame: 250.0,
  DryEraseMagnet: 12.0,
  DryEraseCoroplast: 10.0,
  DryEraseAluminumSandwichBoard: 11.0,
  DryEraseFoamcore: 10.0,
  DryErasePVCBoard: 10.0,
  DTF: 2.5,
  BannerStands: 5.0,
  Banners: 1.25,
  WallArt: 3.3,
};
