import React from "react";
import BannersProductPage from "../../components/Banners/BannersProductPage";
import useProductByName from "../../hooks/product/useProductByName";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../constants/products/pricePerSquareFT";

export const PoleBannerProductPage = () => {
  const productName = "Pole Banner";

  // Fetch the product data by name
  const {
    data: poleBannerProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = poleBannerProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!poleBannerProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <BannersProductPage
      name="Pole Banner"
      images={presignedUrls ?? []}
      bannerOptions={poleBannerProduct?.bannerOptions}
      sizes={poleBannerProduct?.sizes}
      graphic={poleBannerProduct?.graphic}
      material={poleBannerProduct?.material}
      polePocket={poleBannerProduct?.polePocket}
      grommet={poleBannerProduct?.grommet}
      windslit={poleBannerProduct?.windslit}
      path={poleBannerProduct?.path}
      customSizing={false}
      pricePerSqFt={pricePerSquareFootMap.PoleBanner}
    />
  );
};
