import React, { useState, useEffect, useCallback } from "react";
import ImageGallery from "../ImageGallery";
import OptionSection from "../OptionSection";
import ProductDetails from "../ProductDetails";
import OrderDetails from "../OrderDetails";
import { ProductProps } from "../../../interface/product/Product";
import BackButton from "../BackButton";
import CustomSizeInput from "../CustomSizeInput";
import AddToCartButton from "../AddToCartButton";
import { pricePerSquareFootMap } from "../../../constants/products/pricePerSquareFT";

const AdvertisingFlagsProductPage: React.FC<ProductProps> = ({
  name,
  images,
  flagOptions,
  sizes,
  graphic,
  base,
  carryBag,
  path,
  finishing,
  bracket,
  customSizing,
}) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [selectedFlagOption, setSelectedFlagOption] = useState(
    flagOptions?.[0] || { name: "", price: 0 }
  );
  const [selectedSize, setSelectedSize] = useState(
    sizes?.[0] || { name: "", price: 0 }
  );
  const [selectedGraphic, setSelectedGraphic] = useState(
    graphic?.[0] || { name: "", price: 0 }
  );
  const [selectedFinishing, setSelectedFinishing] = useState(
    finishing?.[0] || { name: "", price: 0 }
  );
  const [selectedBase, setSelectedBase] = useState(
    base?.[0] || { name: "", price: 0 }
  );
  const [selectedCarryBag, setSelectedCarryBag] = useState(
    carryBag?.[0] || { name: "", price: 0 }
  );
  const [selectedBracket, setSelectedBracket] = useState(
    bracket?.[0] || { name: "", price: 0 }
  );
  const [jobName, setJobName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [storePickup, setStorePickup] = useState(false);
  const [total, setTotal] = useState(0);
  const [additionalSquareFootCost, setAdditionalSquareFootCost] = useState(0);
  const [customDimensions, setCustomDimensions] = useState({
    heightFt: 0,
    heightIn: 0,
    widthFt: 0,
    widthIn: 0,
  });

  const onSizeChange = useCallback(
    (
      sqft: number,
      cost: number,
      dimensions: {
        heightFt: number;
        heightIn: number;
        widthFt: number;
        widthIn: number;
      }
    ) => {
      setAdditionalSquareFootCost(cost);
      setCustomDimensions(dimensions);
    },
    []
  );

  const calculateTotalPrice = useCallback(() => {
    const baseTotal =
      (selectedFlagOption.price ?? 0) +
      (selectedSize.price ?? 0) +
      (selectedGraphic.price ?? 0) +
      (selectedFlagOption.name === "Flag + Pole"
        ? selectedBase.price ?? 0
        : 0) +
      (selectedCarryBag.price ?? 0) +
      (selectedFlagOption.name === "Flag + Pole"
        ? selectedBracket.price ?? 0
        : 0) +
      (selectedFinishing.price ?? 0) +
      additionalSquareFootCost;
    setTotal(baseTotal * quantity);
  }, [
    selectedFlagOption,
    selectedSize,
    selectedGraphic,
    selectedBase,
    selectedCarryBag,
    selectedBracket,
    selectedFinishing,
    quantity,
    additionalSquareFootCost,
  ]);

  useEffect(() => {
    calculateTotalPrice();
  }, [calculateTotalPrice]);

  const productToAdd = {
    product: name,
    productImg: selectedImage,
    options: {
      "Flag Option": selectedFlagOption.name,
      Size: selectedSize.name,
      Graphic: selectedGraphic.name,
      Base: selectedBase.name,
      "Carry Bag": selectedCarryBag.name,
      Finishing: selectedFinishing.name,
      Bracket: selectedBracket.name,
      "Job Name": jobName,
      Quantity: quantity,
      "Store Pickup": storePickup === true ? "Yes" : "",
      Dimensions: customDimensions,
    },
    total,
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 bg-cool-gray-50 shadow-xl rounded-lg">
      <div className="flex items-center mb-6">
        <BackButton />
        <h1 className="text-3xl font-semibold text-gray-800">{name}</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0">
          <ImageGallery
            images={images}
            selectedImage={selectedImage}
            onSelectImage={setSelectedImage}
          />
        </div>
        <div className="md:w-1/2 space-y-4">
          {customSizing && (
            <div className="bg-white p-6 rounded-lg shadow-md">
              <CustomSizeInput
                onSizeChange={onSizeChange}
                pricePerSqFt={pricePerSquareFootMap.AdvertisingFlag}
              />
            </div>
          )}

          <div className="bg-white p-6 rounded-lg shadow-md">
            {flagOptions && (
              <OptionSection
                title="Flag Option"
                options={flagOptions}
                selected={selectedFlagOption}
                setSelected={setSelectedFlagOption}
                displayType="card"
              />
            )}
            {sizes && (
              <OptionSection
                title="Size"
                options={sizes}
                selected={selectedSize}
                setSelected={setSelectedSize}
                displayType="dropdown"
              />
            )}
            {graphic && (
              <OptionSection
                title="Graphic"
                options={graphic}
                selected={selectedGraphic}
                setSelected={setSelectedGraphic}
                displayType="dropdown"
              />
            )}
            {finishing && (
              <OptionSection
                title="Finishing"
                options={finishing}
                selected={selectedFinishing}
                setSelected={setSelectedFinishing}
                displayType="dropdown"
              />
            )}
            {selectedFlagOption.name === "Flag + Pole" && base && (
              <OptionSection
                title="Base"
                options={base}
                selected={selectedBase}
                setSelected={setSelectedBase}
                displayType="dropdown"
              />
            )}
            {carryBag && (
              <OptionSection
                title="Carry Bag"
                options={carryBag}
                selected={selectedCarryBag}
                setSelected={setSelectedCarryBag}
                displayType="dropdown"
              />
            )}
            {selectedFlagOption.name === "Flag + Pole" && bracket && (
              <OptionSection
                title="Bracket"
                options={bracket}
                selected={selectedBracket}
                setSelected={setSelectedBracket}
                displayType="dropdown"
              />
            )}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md mt-4">
            <OrderDetails
              jobName={jobName}
              setJobName={setJobName}
              quantity={quantity}
              setQuantity={setQuantity}
              storePickup={storePickup}
              setStorePickup={setStorePickup}
            />
            <AddToCartButton product={productToAdd} />
          </div>
        </div>
      </div>
      <ProductDetails
        details={{
          description: "This is a detailed description of the product...",
          breakdown: "Here is a breakdown of the components...",
          warranty: "Warranty information will be detailed here...",
          faq: [
            {
              question: "How long is the product warranty?",
              answer: "The product comes with a 2-year warranty.",
            },
            {
              question: "Can I install this product myself?",
              answer: "Yes, it is designed for easy installation.",
            },
          ],
        }}
      />
    </div>
  );
};

export default AdvertisingFlagsProductPage;
