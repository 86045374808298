import { ChannelLetterProduct } from "../../interface/channel-letter/ChannelLetter";
import StandardChannelLetterExample from "../../assets/images/ChannelLetter/Standard/standardCLExample.jpg";

export const channelLetterProducts: ChannelLetterProduct = {
  description:
    "Ready to install, LED illuminated, UL Listed, with power supplies and installation patterns",
  items: [
    {
      name: "Standard Channel Letters",
      path: "/standard-channel-letters",
      imageUrl: StandardChannelLetterExample,
      description:
        "Acrylic faces with trimcaps. .040 aluminum returns. Front lit & Dual lit",
    },
    {
      name: "Halo/Reverse Lit Channel Letters",
      path: "/halo-reverse-channel-letters",
      description:
        "Fabricated stainless steel welded faces and returns. Prepped, smooth sanded and painted. Multiple colors available",
      options: [
        {
          name: "Reverse Lit",
        },
      ],
    },
    {
      name: "Premium Channel Letters",
      path: "/premium-channel-letters",
      description:
        "Acrylic faces with fabricated and welded stainless steel returns. Prepped, smooth sanded and painted returns. Multiple return colors available",
      options: [
        {
          name: "Full Acrylic Face Lit",
        },
        {
          name: "Inset Acrylic Face Lit",
        },
      ],
    },
  ],
};
