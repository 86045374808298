import React, { useState, useEffect } from "react";

interface CustomSizeInputProps {
  onSizeChange: (
    squareFeet: number,
    additionalCost: number,
    dimensions: {
      heightFt: number;
      heightIn: number;
      widthFt: number;
      widthIn: number;
    }
  ) => void;
  pricePerSqFt: number;
}

const CustomSizeInput: React.FC<CustomSizeInputProps> = ({
  onSizeChange,
  pricePerSqFt,
}) => {
  const [heightFt, setHeightFt] = useState(0);
  const [heightIn, setHeightIn] = useState(0);
  const [widthFt, setWidthFt] = useState(0);
  const [widthIn, setWidthIn] = useState(0);
  const [squareFeet, setSquareFeet] = useState(0);

  useEffect(() => {
    const calculateSquareFeet = () => {
      const totalHeightInches = heightFt * 12 + heightIn;
      const totalWidthInches = widthFt * 12 + widthIn;
      const totalSquareFeet = (totalHeightInches * totalWidthInches) / 144;
      setSquareFeet(totalSquareFeet);
      const newAdditionalCost = totalSquareFeet * pricePerSqFt;
      onSizeChange(totalSquareFeet, newAdditionalCost, {
        heightFt,
        heightIn,
        widthFt,
        widthIn,
      });
    };

    calculateSquareFeet();
  }, [heightFt, heightIn, widthFt, widthIn, onSizeChange, pricePerSqFt]);

  return (
    <div className="p-4 bg-white shadow-sm rounded-lg">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">
        Enter Custom Size:
      </h3>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Height:
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={heightFt}
              onChange={(e) => setHeightFt(+e.target.value)}
              placeholder="Feet"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="text-sm font-medium text-gray-700">ft</span>
            <input
              type="number"
              value={heightIn}
              onChange={(e) => setHeightIn(+e.target.value)}
              placeholder="Inches"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="text-sm font-medium text-gray-700">in</span>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Width:
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={widthFt}
              onChange={(e) => setWidthFt(+e.target.value)}
              placeholder="Feet"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="text-sm font-medium text-gray-700">ft</span>
            <input
              type="number"
              value={widthIn}
              onChange={(e) => setWidthIn(+e.target.value)}
              placeholder="Inches"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="text-sm font-medium text-gray-700">in</span>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-800">
          Square Feet: {squareFeet.toFixed(2)} ft&sup2;
        </p>
      </div>
    </div>
  );
};

export default CustomSizeInput;
